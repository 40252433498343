import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  Box,
  Grid,
  styled,
  Typography,
  Paper,
} from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import {
  validateEmployeenew,
  validateUsers,
  validateGuestUsersNew,
} from '../../services/validators';

import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import http from '../../services/http';
import { VariableSizeList as List } from 'react-window';
import moment from 'moment';

const style = {
  padding: '20px 30px',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',

    fontSize: '16px',
    fontFamily: 'OpenSans',
    borderRadius: '5px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    width: 200,
    fontSize: '16px',
    padding: '3px 10px',
    fontFamily: 'OpenSans',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999999,
  }),
  option: (provided, state) => ({
    padding: '10px',
    width: '100%',
    fontSize: '16px',
    fontFamily: 'OpenSans',

    backgroundColor:
      state.isFocused && !state.isSelected
        ? '#cce9ff'
        : state.isSelected
          ? '#2589f5'
          : 'transparent',
    color: state.isSelected ? '#ffffff' : '#000000',
    cursor: state.isFocused ? 'pointer' : 'auto',
  }),
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
  editMode,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(event) => cb(event.target.value)}
        required
        disabled={!editMode}
        type={type}
        autoComplete="new-password"
      />
    </>
  );
};
const AddUserPopup = ({
  open,
  handleClose,
  handleAddNewUser,
  cibusEnabled,
  handleAddNewGuestUser,
  Roles,
  Kitchens,
  UserStatuses,
  setPayload,
  payload,
  handleUpdateUser,
  handleUpdateGuestUser,
  setIsEdit,
  isUpdate,
  userData,
  t,
  i18n,
  setRolesIds,
  rolesIds,
  setKitchensIds,
  kitchensIds,
  errors,
  setErrors,
  newRoles,
  user,
  contractorList,
  showContractor,
  setShowContractor,
  setSelectContractor,
  selectContractor,
  setshowGuestUser,
  showGuestUser,
  setselectGuestUser,
  selectGuestUser,
  getMealTypes,
  setMealTypesIds,
  mealTypesIds,
  forceResetPassword,
  setForceResetPassword,
  setMealTypesKitchenWise,
  getMealTypesKitchenWise,
  cardGenerationUrlForUser,
  employeeNumberLengthForUser,
  cardNumberLengthForUser,
  isStrippedCard,
  fieldNamesSettings,
  countryOptions,
  visitPurposeValues,
  isMultiOrders,
  setIsMultiOrders,
  allUsers,
  setViewDetails,
  capitalizeEachWord,
  companyId,
}) => {
  const [cardGenerationFunc, setCardGenerationFunc] = useState();
  const [showAdditionalParams, setShowAdditionalParams] = useState(false);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  useEffect(() => {
    if (open) {
      if (payload.role[0] === 6 || payload.role[0] === 7) {
        setshowGuestUser(false);
        setShowContractor(true);
      } else if (payload.role[0] === 8) {
        setShowContractor(false);
        setshowGuestUser(true);
      } else {
        setshowGuestUser(false);
        setShowContractor(false);
      }
    }
  }, [open]);
  useEffect(() => {
    setSelectContractor(
      payload?.contractors?.map((contractor) => {
        return {
          value: contractor.id,
          label: ContractorMap_ValueId[contractor.id],
        };
      })
    );
  }, []);
  useEffect(() => {
    if (contractorList && Array.isArray(contractorList)) {
      setPayload({
        ...payload,
        contractorsIds: [contractorList[0].id],
        magneticStripe: contractorList[0]?.magneticStripe ?? '',
      });
    }
  }, []);
  function countUpperCaseLetters(str) {
    const uppercaseLetters = str.match(/\p{Lu}/gu);
    return uppercaseLetters ? uppercaseLetters.length : 0;
  }

  function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  const height = 40;
  const MenuList = ({
    options,
    children,
    maxHeight,
    width,
    getValue,
    selectProps,
  }) => {
    const objects = document.querySelectorAll('.input-field');
    const elementsArray = Array.from(objects);
    const isRoleDropdown =
      children &&
      Array.isArray(children) &&
      children.length > 0 &&
      children[0].props.selectProps.name === 'rolesIds';
    const roleWidth =
      elementsArray.find((el) => el.classList.contains('role-input-field'))
        .offsetWidth - 40;
    const otherDropdownsWidth =
      elementsArray && elementsArray[1]
        ? elementsArray[1].offsetWidth - 26
        : '';
    const [value] = getValue();
    const initialOffset = options.length < 5 ? options.length * height : 200;
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = '16px OpenSans';

    const getItemSize = (index) => {
      let colRows = children[index]?.props
        ? Math.ceil(
            ctx.measureText(children[index].props.data.label).width /
              (isRoleDropdown
                ? children.length < 5
                  ? roleWidth + 5
                  : roleWidth
                : children.length < 5
                  ? otherDropdownsWidth + 6
                  : otherDropdownsWidth)
          )
        : 1;

      colRows = colRows > 1 ? colRows * 0.75 : colRows;
      return 40 * colRows;
    };
    const gridRef = useResetCache(children.length);
    return (
      <List
        height={
          children.length
            ? children.length < 5
              ? children.reduce((acc, curr) => {
                  let colRows = curr?.props
                    ? Math.ceil(
                        Math.round(
                          ctx.measureText(
                            Array.isArray(curr.props.data.label)
                              ? curr.props.data.label[0].trim()
                              : curr.props.data.label.trim()
                          ).width
                        ) /
                          (isRoleDropdown
                            ? children.length < 5
                              ? roleWidth + 5
                              : roleWidth
                            : children.length < 5
                              ? otherDropdownsWidth + 6
                              : otherDropdownsWidth)
                      )
                    : 1;
                  colRows = colRows > 1 ? colRows * 0.75 : colRows;
                  return acc + 44 * colRows;
                }, 0)
              : 200
            : 0
        }
        ref={gridRef}
        itemCount={children.length}
        itemSize={getItemSize}
        className="customList"
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };
  const KitchensMap = Kitchens.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const MealTypesMap = getMealTypes?.reduce((acc, mealType) => {
    return { ...acc, [mealType.id]: mealType.display };
  }, {});
  const RolesMap_ValueId = newRoles?.reduce((acc, role) => {
    if (role.id !== 5) {
      return { ...acc, [role.id]: role.display };
    } else {
      return acc;
    }
  }, {});
  const ContractorMap_ValueId = contractorList?.reduce((acc, contractor) => {
    return { ...acc, [contractor.id]: contractor.name };
  }, {});
  const StatusMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.display };
  }, {});

  useEffect(() => {
    if (cardGenerationUrlForUser && open) {
      const fetch = async () => {
        const response = await http.get(cardGenerationUrlForUser);
        const func = new Function(`return ${response.cardGenerationWD}`);
        setCardGenerationFunc(func);
      };
      fetch();
    }
    if (isUpdate && payload?.mealTypeId && payload?.mealTypeId.length !== 0) {
      // setMealTypesIds([newMealTypeDateArray[0].value]);
      let res;
      setPayload((prev) => {
        res = prev?.mealTypeId.filter(
          (elem) => MealTypesMap[elem] !== undefined
        );
        return { ...prev, mealTypesIds: res, mealTypeId: res };
      });
      setMealTypesIds(
        res
          ? res.map((el) => {
              return { id: el, label: MealTypesMap[elem] };
            })
          : []
      );
    }
  }, [open]);

  const [role] = useState(4);
  // const [errors, setErrors] = useState({});
  const [pError, setPError] = useState(false);
  const [status, setStatus] = useState({ value: 3, label: 'Active' });

  const handleChange = (name, value) => {
    if (name === 'isPasswordTemporary') {
      setForceResetPassword(!forceResetPassword);
      value = !forceResetPassword;
    }
    if (name === 'isMultiOrders') {
      setIsMultiOrders(!isMultiOrders);
      value = !isMultiOrders;
    }
    const { isValid, error } = validateUsers(
      value,
      name,
      cardNumberLengthForUser,
      employeeNumberLengthForUser,
      payload.role[0] == 8 ? 'guest' : 'emp'
    );
    setErrors({ ...errors, ...error });
    if (cardGenerationUrlForUser && cardGenerationFunc) {
      if (name === 'employeeId' && value) {
        const result = cardGenerationFunc(value);
        setPayload((prev) => ({ ...prev, card: result }));
      }
      if (name === 'employeeId' && !value) {
        setPayload((prev) => ({ ...prev, card: '' }));
      }
    }
    setPayload((prev) => ({ ...prev, [name]: value }));
    if (isUpdate && payload['role'][0] === 8) {
      setshowGuestUser(true);
    }
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const role = payload?.role ? payload?.role : rolesIds ? [rolesIds] : null;
    let details = {
      firstName: payload.fName,
      lastName: payload.lName,
      email: payload.email,
      phone: payload.phone,
      userStatusId: UserStatuses.find((el) => el.display === payload.status)
        ? {
            value: UserStatuses.find((el) => el.display === payload.status)?.id,
            label: UserStatuses.find((el) => el.display === payload.status)
              ?.display,
          }
        : { value: 3, label: StatusMap[3] },
      password: payload.password === '' ? undefined : payload.password,
      cardId:
        !payload.card || payload.card === '' || payload.card === '--'
          ? null
          : isStrippedCard
            ? payload.card.replace(/^0+/, '')
            : payload.card,
      employeeId: payload.employeeId,
      roleId: role,
      kitchensIds,
      contractorsIds: payload?.contractorsIds
        ? payload?.contractorsIds
        : payload?.contractors
          ? payload?.contractors.map((el) => el.id)
          : null,
      connectTeam: payload.connectTeam,
      magneticStripe: payload.magneticStripe,
      hireFrom: payload?.hireFrom ? payload?.hireFrom : null,
      hireTill: payload?.hireTill ? payload?.hireTill : null,
      mealTypeId: payload?.mealTypeId ? payload?.mealTypeId : null,
      isPasswordTemporary: rolesIds === 8 ? false : payload.isPasswordTemporary,
      company: payload?.company,
      visitorCountry: payload?.visitorCountry
        ? Array.isArray(payload?.visitorCountry)
          ? payload?.visitorCountry[0]
          : payload?.visitorCountry
        : '',
      visitPurpose: payload?.visitPurpose
        ? Array.isArray(payload?.visitPurpose)
          ? payload?.visitPurpose[0]
          : payload?.visitPurpose
        : '',
      guestId: payload?.guestId,
      isMultiOrders: payload?.isMultiOrders,
      invitedBy: payload?.invitedBy,
      secondRole: payload?.secondRole,
    };
    if (showGuestUser && !isUpdate) {
      const { isValid, errors } = validateGuestUsersNew(details);
      setErrors(errors);
      if (kitchensIds && kitchensIds?.length == 0)
        return setErrors({ ...errors, kitchensIds: 'Kitchen is required' });
      if (isValid) {
        details.cardId = '';
        details.employeeId = '';
        details.password = '';
        setViewDetails(false);
        //details.contractorId = '';
        await handleAddNewGuestUser({ ...details });
      }
    } else {
      const { isValid, errors } = validateEmployeenew(
        details,
        cardNumberLengthForUser,
        employeeNumberLengthForUser,
        companyId
      );
      setErrors(errors);
      if (!role[0]) return setErrors({ ...errors, role: 'Role is required' });
      if (kitchensIds && kitchensIds?.length == 0)
        return setErrors({ ...errors, kitchensIds: 'Kitchen is required' });
      if (rolesIds == 6 || rolesIds == 7) {
        if (details?.contractorsIds && details?.contractorsIds.length === 0) {
          return setErrors({
            ...errors,
            contractorId: 'Contractor is required',
          });
        }
      }
      // if (!selectContractor && showContractor === true) {
      //   return setErrors({ ...errors, contractor: 'Contractor is required' });
      // }
      if (isValid) {
        setViewDetails(false);
        await handleAddNewUser({ ...details });
        delete details.hireFrom;
        delete details.hireTill;
        delete details.mealTypeId;
      } else {
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isKeyboard =
        window.innerHeight < document.documentElement.clientHeight;
      setKeyboardVisible(isKeyboard);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const CheckPassword = (value) => {
    setStatus(value);
    setPayload({ ...payload, status: value.label });
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup add-employee-popup-mob"
      fullScreen={window.innerWidth < 550}
    >
      <Box sx={style}>
        <div className="user-popup-heading">
          <h6>{isUpdate ? t('updateUser') : t('addUsers')}</h6>

          <div className="close-button">
            <img
              src={cancel}
              alt="close"
              onClick={() => {
                handleClose();
                setShowAdditionalParams(false);
              }}
            />
          </div>
        </div>
        <Box id="modal-modal-description" className="width-add-user">
          <form className="add-user-form">
            <div
              className={`user-create-form item ${
                payload?.role[0]
                  ? 'user-create-form-adaptivity'
                  : 'user-create-form-adaptivity-without-overflow'
              }`}
            >
              <div className="add-user-roles-block">
                <div className="input-field role-input-field">
                  <label>{t('roles') + '*'}</label>
                  <Select
                    // isMulti
                    name={'rolesIds'}
                    components={{ MenuList }}
                    defaultValue={payload?.role?.map((el) => {
                      return { value: el, label: RolesMap_ValueId[el] };
                    })}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 2147483647,
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 2147483647,
                      }),
                      ...SelectStyles,
                    }}
                    open={true}
                    onChange={(newData) => {
                      setErrors({});
                      setPayload({});
                      setKitchensIds([]);
                      if (newData.value === 6 || newData.value === 7) {
                        setshowGuestUser(false);
                        setShowContractor(true);
                      } else if (newData.value === 8) {
                        setShowContractor(false);
                        setshowGuestUser(true);
                      } else {
                        setshowGuestUser(false);
                        setShowContractor(false);
                      }
                      let magneticStripe = payload.magneticStripe;
                      if (
                        contractorList &&
                        selectContractor &&
                        selectContractor.length !== 0 &&
                        contractorList
                          .filter((elem) => selectContractor.includes(elem.id))
                          .find((elem) => elem.magneticStripe !== '') !==
                          undefined
                      ) {
                        magneticStripe = '';
                      }
                      if (
                        !(
                          newData.value === 6 ||
                          newData.value === 7 ||
                          newData.value === 8
                        )
                      ) {
                        setSelectContractor([]);
                      }
                      setRolesIds(newData.value);
                      setPayload({
                        ...payload,
                        role: [newData.value],
                        contractors: !(
                          newData.value === 6 ||
                          newData.value === 7 ||
                          newData.value === 8
                        )
                          ? []
                          : payload.contractors,
                        contractorsIds: !(
                          newData.value === 6 ||
                          newData.value === 7 ||
                          newData.value === 8
                        )
                          ? []
                          : payload.contractorsIds,
                        contractor: !(
                          newData.value === 6 ||
                          newData.value === 7 ||
                          newData.value === 8
                        )
                          ? []
                          : payload.contractor,
                        magneticStripe: magneticStripe,
                      });

                      if (
                        contractorList &&
                        Array.isArray(contractorList) &&
                        contractorList.length === 1 &&
                        (newData.value === 6 ||
                          newData.value === 7 ||
                          newData.value === 8)
                      ) {
                        const elem = contractorList[0];
                        if (newData.value === 6 || newData.value === 8) {
                          setSelectContractor([elem?.id]);
                        }
                        setPayload((prev) => {
                          return {
                            ...prev,
                            contractorsIds: [elem?.id],
                            magneticStripe: cibusEnabled
                              ? elem?.magneticStripe ?? ''
                              : '',
                          };
                        });
                      }
                      if (newData.value === 8) {
                        setKitchensIds([Kitchens[0].id]);
                        const mealTypesArr = getMealTypes.filter(
                          (elem) => elem?.kitchenId === Kitchens[0].id
                        );
                        setMealTypesKitchenWise(mealTypesArr);
                        setPayload((prev) => {
                          return {
                            ...prev,
                            kitchensIds: [Kitchens[0].id],
                            mealTypeId: [mealTypesArr[0].id],
                            invitedBy: [allUsers[0]?.value],
                            contractorsIds: [contractorList[0]?.id],
                          };
                        });
                        handleChange('hireFrom', moment().format('YYYY-MM-DD'));
                        handleChange(
                          'hireTill',
                          moment().add(7, 'days').format('YYYY-MM-DD')
                        );
                      }
                      if (
                        Kitchens &&
                        Array.isArray(Kitchens) &&
                        (Kitchens.length === 1 || newData.value === 8)
                      ) {
                        setKitchensIds([Kitchens[0].id]);
                        const mealTypesArr = getMealTypes.filter(
                          (elem) => elem?.kitchenId === Kitchens[0].id
                        );
                        setMealTypesKitchenWise(mealTypesArr);
                        setPayload((prev) => {
                          return {
                            ...prev,
                            kitchensIds: [Kitchens[0].id],
                          };
                        });
                        console.log('mealTypesArr', mealTypesArr);

                        if (mealTypesArr && mealTypesArr.length === 1) {
                          setPayload((prev) => {
                            return {
                              ...prev,
                              mealTypeId: [mealTypesArr[0].id],
                            };
                          });
                        }
                      }
                    }}
                    options={newRoles
                      ?.filter((el) => el.id !== 5)
                      .map((roles) => {
                        return { value: roles.id, label: roles.display };
                      })}
                    placeholder={t('roles')}
                    required
                  />
                  {errors.role && <p className="error-msg">Role is required</p>}
                </div>
                {payload?.role[0] === 2 ||
                payload?.role[0] === 3 ||
                payload?.role[0] === 4 ||
                payload?.role[0] === 7 ? (
                  <div className="input-field role-input-field">
                    <label>{t('additionalRole')}</label>
                    <Select
                      name={'secondRole'}
                      components={{ MenuList }}
                      isClearable
                      defaultValue={
                        payload?.secondRole
                          ? {
                              value: payload?.secondRole,
                              label: RolesMap_ValueId[payload?.secondRole],
                            }
                          : null
                      }
                      onChange={(newData) => {
                        console.log('newData', newData);
                        setPayload({
                          ...payload,
                          secondRole: newData?.value ?? null,
                        });
                      }}
                      options={newRoles
                        ?.filter((el) =>
                          payload?.role[0] === 4
                            ? el.id === 2 || el.id === 3 || el.id === 7
                            : el.id === 4
                        )
                        .map((roles) => {
                          return { value: roles.id, label: roles.display };
                        })}
                      placeholder={t('roles')}
                      styles={SelectStyles}
                      required
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="line"></div>
              {payload?.role[0] ? (
                <>
                  <div className="user-create-block-wrapper">
                    <p className="user-create-title">{t('mainInfo')}</p>
                    <div className="user-create-block">
                      <div className="input-field w-75">
                        <GetInputField
                          placeholder={t('firstName')}
                          label={t('firstName') + ' *'}
                          name="fName"
                          type="text"
                          value={payload['fName']}
                          cb={(value) => {
                            handleChange('fName', value);
                          }}
                          editMode={true}
                        />
                        {errors && (
                          <p className="error-msg">
                            {errors.fName || errors.firstName}
                          </p>
                        )}
                      </div>
                      <div className="input-field w-75">
                        <GetInputField
                          placeholder={t('lastName')}
                          label={t('lastName') + ' *'}
                          name="lName"
                          type="text"
                          value={payload['lName']}
                          cb={(value) => {
                            handleChange('lName', value);
                          }}
                          required
                          editMode={true}
                        />
                        {errors && (
                          <p className="error-msg">
                            {errors.lName || errors.lastName}
                          </p>
                        )}
                      </div>
                      {showGuestUser ? (
                        ''
                      ) : (
                        <div className="input-field w-75">
                          <GetInputField
                            placeholder={t('phone')}
                            label={t('phone')}
                            name="phone"
                            type="tel"
                            value={payload['phone']?.replace('+', '')}
                            cb={(value) => {
                              handleChange('phone', value);
                            }}
                            required
                            editMode={true}
                          />
                          {errors && (
                            <p className="error-msg">{errors.phone}</p>
                          )}
                        </div>
                      )}

                      {showGuestUser ? (
                        ''
                      ) : (
                        <div className="input-field w-75">
                          <GetInputField
                            placeholder={t('email')}
                            label={
                              showGuestUser ? t('email') : t('email') + ' *'
                            }
                            name="email"
                            type="email"
                            value={payload['email']}
                            cb={(value) => {
                              handleChange('email', value);
                            }}
                            required
                            editMode={true}
                          />
                          {errors && (
                            <p className="error-msg">{errors.email}</p>
                          )}
                        </div>
                      )}

                      {!showGuestUser && (
                        <div className="input-field w-75">
                          <GetInputField
                            placeholder={t('password')}
                            label={t('password')}
                            name="password"
                            type="password"
                            value={payload['password']}
                            cb={(value) => {
                              handleChange('password', value);
                            }}
                            required
                            editMode={true}
                          />
                          {(errors || pError) && (
                            <p className="error-msg">{errors.password}</p>
                          )}
                        </div>
                      )}
                      {!showGuestUser && (
                        <>
                          <div className="input-field w-75">
                            <GetInputField
                              placeholder={
                                fieldNamesSettings?.find(
                                  (el) => el.value === 'employeeId'
                                )
                                  ? fieldNamesSettings
                                      ?.find((el) => el.value === 'employeeId')
                                      ?.translations.find(
                                        (el) =>
                                          el.language.shortCode == i18n.language
                                      )?.text
                                  : t('employeeNo')
                              }
                              label={`${
                                fieldNamesSettings?.find(
                                  (el) => el.value === 'employeeId'
                                )
                                  ? fieldNamesSettings
                                      ?.find((el) => el.value === 'employeeId')
                                      ?.translations.find(
                                        (el) =>
                                          el.language.shortCode == i18n.language
                                      )?.text
                                  : t('employeeNo')
                              } ${companyId === 5 ? ' *' : ''}`}
                              name="employee"
                              type="number"
                              value={payload['employeeId']}
                              cb={(value) => handleChange('employeeId', value)}
                              editMode={true}
                            />
                            {errors && (
                              <p className="error-msg">{errors.employeeNo}</p>
                            )}
                          </div>
                          <div className="input-field w-75">
                            <GetInputField
                              placeholder={
                                fieldNamesSettings?.find(
                                  (el) => el.value === 'cardId'
                                )
                                  ? fieldNamesSettings
                                      ?.find((el) => el.value === 'cardId')
                                      ?.translations.find(
                                        (el) =>
                                          el.language.shortCode == i18n.language
                                      )?.text
                                  : t('cardNo')
                              }
                              label={
                                fieldNamesSettings?.find(
                                  (el) => el.value === 'cardId'
                                )
                                  ? fieldNamesSettings
                                      ?.find((el) => el.value === 'cardId')
                                      ?.translations.find(
                                        (el) =>
                                          el.language.shortCode == i18n.language
                                      )?.text
                                  : t('cardNo')
                              }
                              name="card"
                              type="number"
                              value={payload['card']}
                              cb={(value) => handleChange('card', value)}
                              editMode={cardGenerationUrlForUser ? false : true}
                            />
                            {errors && (
                              <p className="error-msg">{errors.card}</p>
                            )}
                          </div>
                          {cibusEnabled ? (
                            <div className="input-field w-75">
                              <GetInputField
                                placeholder={
                                  fieldNamesSettings?.find(
                                    (el) => el.value === 'magneticStripe'
                                  )
                                    ? fieldNamesSettings
                                        ?.find(
                                          (el) => el.value === 'magneticStripe'
                                        )
                                        ?.translations.find(
                                          (el) =>
                                            el.language.shortCode ==
                                            i18n.language
                                        )?.text
                                    : t('magneticStripe')
                                }
                                label={
                                  fieldNamesSettings?.find(
                                    (el) => el.value === 'magneticStripe'
                                  )
                                    ? fieldNamesSettings
                                        ?.find(
                                          (el) => el.value === 'magneticStripe'
                                        )
                                        ?.translations.find(
                                          (el) =>
                                            el.language.shortCode ==
                                            i18n.language
                                        )?.text
                                    : t('magneticStripe')
                                }
                                name="magneticStripe"
                                type="number"
                                value={payload['magneticStripe']}
                                cb={(value) => {
                                  handleChange('magneticStripe', value);
                                }}
                                editMode={true}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="user-create-block-wrapper">
                    <p className="user-create-title">{t('Settings')}</p>
                    <div className="user-create-block">
                      <div className="input-field w-75">
                        <label>{t('kitchenname') + ' *'}</label>
                        <Select
                          isMulti
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 2147483647,
                            }),
                            ...SelectStyles,
                          }}
                          name={'kitchensIds'}
                          components={{ MenuList }}
                          value={kitchensIds?.map((kitchenId) => {
                            return {
                              value: kitchenId,
                              label: KitchensMap[kitchenId],
                            };
                          })}
                          isBigArray={false}
                          onChange={(newData) => {
                            setKitchensIds(newData.map((k) => k.value));
                            setPayload({
                              ...payload,
                              kitchensIds: newData.map((k) => k.value),
                            });

                            let newMealTypeDateArray = [];
                            newData?.forEach((elData) => {
                              let newMealTypeInfo = getMealTypes?.filter(
                                (elInfo) => elInfo?.kitchenId === elData?.value
                              );
                              newMealTypeDateArray = [
                                ...newMealTypeDateArray,
                                ...newMealTypeInfo,
                              ];
                            });
                            setMealTypesKitchenWise(newMealTypeDateArray);
                            if (newMealTypeDateArray.length === 1) {
                              setMealTypesIds([newMealTypeDateArray[0].value]);
                              setPayload({
                                ...payload,
                                mealTypeId: [newMealTypeDateArray[0].id],
                              });
                            }
                            if (
                              payload?.mealTypeId &&
                              payload?.mealTypeId?.length > 0
                            ) {
                              let newMealTypeDateArrayInfo = [];
                              newMealTypeDateArray?.forEach((elDataInfo) => {
                                let newMealTypeInfo1 =
                                  payload.mealTypeId?.filter(
                                    (elInfoID) => elInfoID === elDataInfo?.id
                                  );
                                newMealTypeDateArrayInfo = [
                                  ...newMealTypeDateArrayInfo,
                                  ...newMealTypeInfo1,
                                ];
                              });
                              setPayload({
                                ...payload,
                                mealTypeId: newMealTypeDateArrayInfo,
                              });
                            }
                          }}
                          options={
                            Kitchens && user.kitchens
                              ? (user.roleId == 1
                                  ? Kitchens
                                  : Kitchens?.filter((el) =>
                                      user?.KitchenId?.includes(el.id)
                                    )
                                ).map((el) => {
                                  return { value: el.id, label: el.name };
                                })
                              : []
                          }
                          placeholder={t('kitchenname')}
                          required
                        />
                        {errors.kitchensIds && (
                          <p className="error-msg">Kitchen is required</p>
                        )}
                      </div>
                      {showGuestUser && (
                        <>
                          <div className="input-field w-75">
                            <label>{t('mealType') + ' *'}</label>
                            <Select
                              isMulti
                              name={'mealTypeId'}
                              components={{ MenuList }}
                              // value={mealTypesIds?.map((el) => {
                              //   return { value: el, label: MealTypesMap[el] };
                              // })}
                              value={
                                Array.isArray(payload?.mealTypeId)
                                  ? payload?.mealTypeId?.map((el) => {
                                      return {
                                        value: el,
                                        label: MealTypesMap[el],
                                      };
                                    })
                                  : {
                                      value: payload?.mealTypeId,
                                      label: MealTypesMap[payload?.mealTypeId],
                                    }
                              }
                              onChange={(newData) => {
                                setMealTypesIds(newData.map((k) => k.value));
                                setPayload({
                                  ...payload,
                                  mealTypeId: newData.map((k) => k.value),
                                });
                              }}
                              // options={getMealTypes?.map((el) => {
                              //   return { value: el.id, label: el.display };
                              // })}
                              options={getMealTypesKitchenWise?.map((el) => {
                                return { value: el.id, label: el.display };
                              })}
                              placeholder={t('mealType')}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 2147483647,
                                }),
                                ...SelectStyles,
                              }}
                              required
                            />
                            {errors.mealTypeIds && (
                              <p className="error-msg">Meal type is required</p>
                            )}
                          </div>
                          <div className="input-field w-75">
                            <label>{`${t('contractor')}  ${
                              payload?.role[0] === 6 ||
                              payload?.role[0] === 7 ||
                              payload?.role[0] === 8
                                ? ' *'
                                : ''
                            }`}</label>
                            <Select
                              name={'contractorIds'}
                              components={{ MenuList }}
                              value={
                                payload?.contractorsIds?.length === 0 &&
                                payload?.contractors?.length === 0
                                  ? []
                                  : payload?.contractorsIds?.map(
                                      (contractor) => {
                                        return {
                                          value: contractor,
                                          label:
                                            ContractorMap_ValueId[contractor],
                                        };
                                      }
                                    ) ||
                                    payload?.contractors?.map((contractor) => {
                                      return {
                                        value: contractor.id,
                                        label:
                                          ContractorMap_ValueId[contractor.id],
                                      };
                                    }) ||
                                    []
                              }
                              onChange={(newData) => {
                                setSelectContractor([newData.value]);
                                setPayload({
                                  ...payload,
                                  contractorsIds: [newData.value],
                                  magneticStripe: cibusEnabled
                                    ? contractorList.find(
                                        (el) => el.id === newData.value
                                      )?.magneticStripe
                                      ? contractorList.find(
                                          (el) => el.id === newData.value
                                        )?.magneticStripe
                                      : payload.magneticStripe
                                    : '',
                                });
                              }}
                              options={contractorList?.map((contractors) => {
                                return {
                                  value: contractors.id,
                                  label: [contractors.name],
                                };
                              })}
                              placeholder={t('contractor')}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 2147483647,
                                }),
                                ...SelectStyles,
                              }}
                              required
                            />
                            {errors && (
                              <p className="error-msg">
                                {errors.contractorsIds}
                              </p>
                            )}
                          </div>
                          <div className="input-field w-75">
                            <label>{t('invitedBy') + ' *'}</label>
                            <Select
                              isClearable
                              name="invitedBy"
                              components={{ MenuList }}
                              value={allUsers.find(
                                (el) => el.value == payload?.invitedBy
                              )}
                              onChange={(newData) => {
                                setPayload({
                                  ...payload,
                                  invitedBy: newData?.value
                                    ? [newData?.value]
                                    : '',
                                });
                              }}
                              options={
                                allUsers
                                  ? allUsers.map((el) => ({
                                      value: el.value,
                                      label: capitalizeEachWord(el.label),
                                    }))
                                  : []
                              }
                              placeholder={t('invitedBy')}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 2147483647,
                                }),
                                ...SelectStyles,
                              }}
                            />
                            {errors && (
                              <p className="error-msg">{errors.invitedBy}</p>
                            )}
                          </div>
                          <div className="input-field w-75">
                            <GetInputField
                              placeholder={t('startDate')}
                              label={t('startDate') + ' *'}
                              name="hireFrom"
                              type="date"
                              value={payload['hireFrom']}
                              cb={(value) => {
                                console.log('value', value);

                                handleChange('hireFrom', value);
                              }}
                              required
                              editMode={true}
                            />
                            {errors && (
                              <p className="error-msg">{errors.hireFrom}</p>
                            )}
                          </div>
                          <div className="input-field w-75">
                            <GetInputField
                              placeholder={t('endDatee')}
                              label={t('endDate') + ' *'}
                              name="hireTill"
                              type="date"
                              value={payload['hireTill']}
                              cb={(value) => {
                                handleChange('hireTill', value);
                              }}
                              required
                              editMode={true}
                            />
                            {errors && (
                              <p className="error-msg">{t(errors.hireTill)}</p>
                            )}
                          </div>
                        </>
                      )}
                      {showContractor ||
                      payload?.secondRole === 4 ||
                      payload?.role[0] === 7 ||
                      payload?.role[0] === 4 ? (
                        <div className="input-field w-75">
                          <label>{`${t('contractor')}  ${
                            payload?.role[0] === 6 ||
                            payload?.role[0] === 7 ||
                            payload?.role[0] === 8
                              ? ' *'
                              : ''
                          }`}</label>
                          <Select
                            isMulti={payload?.role[0] === 7 ? true : false}
                            name={'contractorsIds'}
                            components={{ MenuList }}
                            isClearable={payload?.role[0] === 4}
                            value={
                              payload?.contractorsIds?.length === 0 &&
                              payload?.contractors?.length === 0
                                ? []
                                : payload?.contractorsIds?.map((contractor) => {
                                    return {
                                      value: contractor,
                                      label: ContractorMap_ValueId[contractor],
                                    };
                                  }) ||
                                  payload?.contractors?.map((contractor) => {
                                    return {
                                      value: contractor.id,
                                      label:
                                        ContractorMap_ValueId[contractor.id],
                                    };
                                  }) ||
                                  []
                            }
                            onChange={(newData) => {
                              if (newData === null) {
                                setSelectContractor([]);
                                setPayload({
                                  ...payload,
                                  contractorsIds: [],
                                  magneticStripe: payload?.magneticStripe,
                                  contractors: [],
                                });
                              } else {
                                setSelectContractor(
                                  payload?.role[0] === 7
                                    ? newData.map((k) => k.value)
                                    : newData?.value
                                      ? [newData?.value]
                                      : null
                                );

                                setPayload({
                                  ...payload,
                                  contractorsIds:
                                    payload?.role[0] === 7
                                      ? newData.map((k) => k.value)
                                      : newData?.value
                                        ? [newData?.value]
                                        : newData?.value,
                                  magneticStripe:
                                    payload?.role[0] !== 7 && cibusEnabled
                                      ? payload?.role[0] == 4
                                        ? payload?.magneticStripe
                                        : contractorList.find(
                                            (el) => el?.id === newData?.value
                                          )?.magneticStripe ?? ''
                                      : '',
                                });
                              }
                            }}
                            options={contractorList?.map((contractors) => {
                              return {
                                value: contractors.id,
                                label: [contractors.name],
                              };
                            })}
                            placeholder={t('contractor')}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 2147483647,
                              }),
                              ...SelectStyles,
                            }}
                            required
                          />
                          {errors && (
                            <p className="error-msg">{errors.contractorsIds}</p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}

                      {(user.roleId === 1 || isUpdate) && !showGuestUser ? (
                        <div className="input-field w-75">
                          <label>{t('status')}</label>
                          <Select
                            name={'status'}
                            components={{ MenuList }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 2147483647,
                              }),
                              ...SelectStyles,
                            }}
                            defaultValue={
                              UserStatuses.find(
                                (el) => el.display === payload.status
                              )
                                ? {
                                    value: UserStatuses.find(
                                      (el) => el.display === payload.status
                                    )?.id,
                                    label: UserStatuses.find(
                                      (el) => el.display === payload.status
                                    )?.display,
                                  }
                                : { value: 3, label: StatusMap[3] }
                            }
                            onChange={(newData) => {
                              CheckPassword(newData);
                            }}
                            options={UserStatuses.map((kitchen) => {
                              return {
                                value: kitchen.id,
                                label: kitchen.display,
                              };
                            })}
                            placeholder={t('status')}
                            required
                          />
                          {errors && <p className="error-msg"></p>}
                        </div>
                      ) : (
                        ''
                      )}
                      {!showGuestUser && (
                        <div
                          className={`input-field type-checkbox ${
                            i18n.language === 'he' ? 'type-checkbox-he' : ''
                          } add-user-checkbox`}
                        >
                          <input
                            type="checkbox"
                            checked={payload['isPasswordTemporary']}
                            name="isPasswordTemporary"
                            value={payload['isPasswordTemporary']}
                            onClick={(value) => {
                              setPayload({
                                ...payload,
                                contractorsIds: [],
                                magneticStripe: payload?.magneticStripe,
                                contractors: [],
                              });
                            }}
                          />
                          <label>{t('resetPassword')}</label>
                        </div>
                      )}
                      {!showGuestUser ? (
                        <div
                          className="input-field w-75"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: 12,
                              lineHeight: '18px',
                              color: '#23283c',
                            }}
                          >
                            {t('connectteam')}
                          </span>
                          <button
                            type="button"
                            className={`toggle-btn ${
                              payload.connectTeam === '1' ||
                              payload.connectTeam === 1
                                ? 'toggle-btn-on'
                                : 'toggle-btn-off'
                            }`}
                            style={{ maxWidth: 50 }}
                            onClick={() =>
                              setPayload({
                                ...payload,
                                connectTeam: payload.connectTeam === 1 ? 0 : 1,
                              })
                            }
                          >
                            <span></span>
                          </button>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {showGuestUser ? (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 15,
                      }}
                    >
                      <div className="line"></div>
                      <>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 0,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setShowAdditionalParams(!showAdditionalParams);
                          }}
                        >
                          <p className="user-create-title">
                            {t('additionalParams')}
                          </p>
                          <div
                            className={
                              showAdditionalParams ? 'arrow-top' : 'arrow-down'
                            }
                          />
                        </div>
                        {showAdditionalParams ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 15,
                            }}
                          >
                            <div className="line"></div>
                            <div className="user-create-block-wrapper">
                              <p className="user-create-title">
                                {t('mainInfo')}
                              </p>
                              <div className="user-create-block">
                                <div className="input-field w-75">
                                  <GetInputField
                                    placeholder={t('phone')}
                                    label={t('phone')}
                                    name="phone"
                                    type="tel"
                                    value={payload['phone']?.replace('+', '')}
                                    cb={(value) => {
                                      handleChange('phone', value);
                                    }}
                                    required
                                    editMode={true}
                                  />
                                  {errors && (
                                    <p className="error-msg">{errors.phone}</p>
                                  )}
                                </div>
                                <div className="input-field w-75">
                                  <GetInputField
                                    placeholder={t('email')}
                                    label={
                                      showGuestUser
                                        ? t('email')
                                        : t('email') + ' *'
                                    }
                                    name="email"
                                    type="email"
                                    value={payload['email']}
                                    cb={(value) => {
                                      handleChange('email', value);
                                    }}
                                    required
                                    editMode={true}
                                  />
                                  {errors && (
                                    <p className="error-msg">{errors.email}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="line"></div>
                            <div className="user-create-block-wrapper">
                              <p className="user-create-title">
                                {t('Settings')}
                              </p>
                              <div className="user-create-block">
                                <div className="input-field w-75">
                                  <GetInputField
                                    placeholder={t('guestId')}
                                    label={t('guestId')}
                                    name="guestId"
                                    type="string"
                                    value={payload['guestId']}
                                    cb={(value) => {
                                      handleChange('guestId', value);
                                    }}
                                    editMode={true}
                                  />
                                  {errors && (
                                    <p className="error-msg">
                                      {errors.guestId}
                                    </p>
                                  )}
                                </div>

                                <div className="input-field w-75">
                                  <GetInputField
                                    placeholder={t('company')}
                                    label={t('company')}
                                    name="Company"
                                    type="string"
                                    value={payload['company']}
                                    cb={(value) => {
                                      handleChange('company', value);
                                    }}
                                    editMode={true}
                                  />
                                  {errors && (
                                    <p className="error-msg">
                                      {errors.company}
                                    </p>
                                  )}
                                </div>

                                <div className="input-field w-75">
                                  <label>{t('visitorCountry')}</label>
                                  <Select
                                    name={'visitorCountry'}
                                    components={{ MenuList }}
                                    value={countryOptions.find(
                                      (el) =>
                                        el.value == payload?.visitorCountry ||
                                        el.label == payload?.visitorCountry
                                    )}
                                    isClearable
                                    onChange={(newData) => {
                                      setPayload({
                                        ...payload,
                                        visitorCountry: [newData?.label],
                                      });
                                    }}
                                    options={countryOptions}
                                    placeholder={t('visitorCountry')}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 2147483647,
                                      }),
                                      ...SelectStyles,
                                    }}
                                  />
                                  {errors && (
                                    <p className="error-msg">
                                      {errors.visitorCountry}
                                    </p>
                                  )}
                                </div>

                                <div className="input-field w-75">
                                  <label>{t('visitPurpose')}</label>
                                  <Select
                                    name={'visitPurpose'}
                                    components={{ MenuList }}
                                    value={visitPurposeValues.find(
                                      (el) =>
                                        el.value == payload?.visitPurpose ||
                                        el.label == payload?.visitPurpose
                                    )}
                                    isClearable
                                    onChange={(newData) => {
                                      setPayload({
                                        ...payload,
                                        visitPurpose: [newData?.value],
                                      });
                                    }}
                                    options={visitPurposeValues}
                                    placeholder={t('visitPurpose')}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 2147483647,
                                      }),
                                      ...SelectStyles,
                                    }}
                                  />
                                  {errors && (
                                    <p className="error-msg">
                                      {errors.visitPurpose}
                                    </p>
                                  )}
                                </div>
                                <div className="input-field w-75">
                                  <label>{t('status')}</label>
                                  <Select
                                    name={'status'}
                                    components={{ MenuList }}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 2147483647,
                                      }),
                                      ...SelectStyles,
                                    }}
                                    defaultValue={
                                      UserStatuses.find(
                                        (el) => el.display === payload.status
                                      )
                                        ? {
                                            value: UserStatuses.find(
                                              (el) =>
                                                el.display === payload.status
                                            )?.id,
                                            label: UserStatuses.find(
                                              (el) =>
                                                el.display === payload.status
                                            )?.display,
                                          }
                                        : { value: 3, label: StatusMap[3] }
                                    }
                                    onChange={(newData) => {
                                      CheckPassword(newData);
                                    }}
                                    options={UserStatuses.map((kitchen) => {
                                      return {
                                        value: kitchen.id,
                                        label: kitchen.display,
                                      };
                                    })}
                                    placeholder={t('status')}
                                    // isMulti
                                    required
                                  />
                                  {errors && <p className="error-msg"></p>}
                                </div>
                                <div
                                  className={`input-field type-checkbox ${
                                    i18n.language === 'he'
                                      ? 'type-checkbox-he'
                                      : ''
                                  } add-user-checkbox`}
                                >
                                  <input
                                    type="checkbox"
                                    checked={payload['isMultiOrders']}
                                    name="isMultiOrders"
                                    value={payload['isMultiOrders']}
                                    onClick={(value) => {
                                      handleChange('isMultiOrders', value);
                                    }}
                                  />
                                  <label>{t('isMultiOrders')}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                      <div className="line"></div>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}
            </div>

            <canvas id="canvas" width="0" height="0"></canvas>
            {isUpdate ? (
              <div
                className="add-employee-btn add-employee-btn-in-popup"
                style={{
                  bottom: isKeyboardVisible
                    ? '0'
                    : 'env(safe-area-inset-bottom)',
                }}
              >
                {showGuestUser ? (
                  <Button onClick={handleUpdateGuestUser}>{t('update')}</Button>
                ) : (
                  <Button onClick={handleUpdateUser}>{t('update')}</Button>
                )}
              </div>
            ) : (
              <div
                className="add-employee-btn add-employee-btn-in-popup"
                style={{
                  bottom: isKeyboardVisible
                    ? '0'
                    : 'env(safe-area-inset-bottom)',
                }}
              >
                <Button onClick={handleFormSubmit}>{t('add')}</Button>
              </div>
            )}
          </form>{' '}
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  employeeNumberLengthForUser: state.companyConfig.employeeNumberLengthForUser,
  cardNumberLengthForUser: state.companyConfig.cardNumberLengthForUser,
  cardGenerationUrlForUser: state.companyConfig.cardGenerationUrlForUser,
  isStrippedCard: state.companyConfig.isStrippedCard,
  companyId: state.companyConfig.companyId,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserPopup);
