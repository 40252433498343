import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../../services/http';
import Select from 'react-select';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import User from './User';
import { isTabletOrMobile } from '../../services/mediaQueries';
import { sleep } from '../../services/utils';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import AddContractorPopup from './AddContractor';
import { ActiveButton } from '../../components/WE-Button';
import { validateContractor } from '../../services/validators';
import CopyUrlUser from './copyUrlUser';
import ConfirmationPopup from './confirmation';
import { Autocomplete } from '@material-ui/lab';
import Tippy from '@tippyjs/react';
import { useHistory } from 'react-router';
import file from '../../assets/images/file-export.svg';
import adduser from '../../assets/images/add-user.svg';
import search from '../../assets/images/Search.svg';
import { Button } from 'react-bootstrap';
import more from '../../assets/images/more.svg';
import next from '../../assets/images/next.png';
import ContractDetails from './ContractDetails';
import moment from 'moment';
import agGridLocales from '../../translations/ag-grid';

const initialState = {
  userId: null,
  name: '',
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: [],
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'active',
  isInvoice: 0,
  magneticStripe: '',
};

const ContractorManagement = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  languages,
  currentLanguage,
  cibusEnabled,
  fieldNamesSettings,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [kitchensIds, setKitchensIds] = useState([]);
  const [payload, setPayload] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [rolesIds, setRolesIds] = useState();
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [errors, setErrors] = useState({});
  const [columns, setcolumns] = useState();
  const [details, setDetails] = useState();
  const [viewDetails, setViewDetails] = useState();
  const [userStatus, setUserStatus] = useState(5);
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  /***********fetch User data */
  useEffect(() => {
    const fetchData1 = async () => {
      const { data, total } = await useFetchingLoader(
        async () => await http.get(`contractors/getAllContractors`)
      );
      setPrefetchData(data);
      let serverUsers = data.map((u, i) => ({
        userId: u.id,
        name: u?.name ? u?.name : '',
        contactPersonName: u?.contactPersonName ? u?.contactPersonName : '',
        contactPhone: u?.contactPhone ? u?.contactPhone : '',
        contactEmail: u?.contactEmail ? u?.contactEmail : '',
        companyNumber: u?.companyNumber ? u?.companyNumber : '',
        'Registration Date': u?.createdAt
          ? u.createdAt.split('T')[0]
          : undefined /* Format : YYYY-MM-DD */,
        magneticStripe: u?.magneticStripe ? u?.magneticStripe : '',
        isInvoice: u?.isInvoice,
        cutOffTime: u?.cutOffTime,
        isPaymentRequiredForPickup: u?.isPaymentRequiredForPickup,
      }));

      setUsersToDisplay(serverUsers);
    };
    fetchData1();
  }, [dummy]);

  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      setPayload(initialState);
      setIsUpdate(false);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setKitchensIds([]);
    setErrors({});
  };

  /******* add New User */
  const handleAddNewUser = async (userData) => {
    userData.contactEmail =
      userData.contactEmail?.length > 0 ? userData.contactEmail : null;
    userData.contactPhone =
      userData.contactPhone?.length > 0 ? userData.contactPhone : null;

    try {
      let userToAdd;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`contractors/createContractors`, {
          ...userData,
        });
      });
      setTimeout(() => {
        Dialog({
          text: t('addedmessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(!dummy);
          },
        });
      }, 150);
      setViewDetails(false);
      handleClose();
    } catch (err) {
      const error1 = t(err.split(' ')[0].split('_')[0]);
      const error2 = err.split(' ');
      error2.shift();
      error2.unshift(error1);
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const handleUpdateUser = async () => {
    try {
      let details = {
        name: payload.name,
        contactPersonName: payload.contactPersonName,
        contactEmail: payload.contactEmail,
        contactPhone: payload.contactPhone,
        companyNumber: payload.companyNumber,
        isInvoice: payload?.isInvoice,
        magneticStripe: payload?.magneticStripe ?? '',
        cutOffTime: payload?.cutOffTime,
        isPaymentRequiredForPickup: payload?.isPaymentRequiredForPickup,
      };
      const { isValid, errors } = validateContractor(details);
      setErrors({ ...errors });
      if (!isValid) {
        return;
      }
      const userId = payload.userId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`contractors/updateContractor/${userId}`, {
            ...details,
            contactPhone:
              details.contactPhone !== ''
                ? details.contactPhone
                : details.contactPhone,
          })
      );
      setTimeout(() => {
        Dialog({
          title: t('updatedMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(!dummy);
            setViewDetails(false);
            handleClose();
          },
        });
      }, 150);
      setKitchensIds([]);
    } catch (err) {
      console.log('err', err);
      const error1 = t(err.split(' ')[0].split('_')[0]);
      const error2 = err.split(' ');
      error2.shift();
      error2.unshift(error1);
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const handleDeleteUser = async (userId) => {
    // setDummy(false);
    try {
      // let details = {
      //   userStatusId: 5,
      // };
      const response = await http.delete(
        `contractors/deleteContractor/${deleteId}`
      );
      setConfirmation(false);
      if (response) {
        Dialog({
          title: t('deleteMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(!dummy);
            setViewDetails(false);
          },
        });
      }
      setDeleteId('');
    } catch (err) {
      setConfirmation(false);
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };
  /************global filter funcitionm */
  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };

  /***********Export CSV file */
  const onBtnExport = () => {
    gridApi.exportDataAsCsv({
      fileName:
        'Contractor' + '-' + moment(new Date()).format('YYYY-MM-DD hh:mm A'),
    });
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  // ag grid data
  const columnDefs = [
    {
      field: 'contactPersonName',
      headerName: t('contactPersonName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.contactPersonName}`
          ? `<b>${params.data.contactPersonName}</b> <br /> ${params.data.contactEmail}`
          : '--';
      },
    },
    {
      field: 'Registration Date',
      headerName: t('registrationDate'),
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data['Registration Date']}`
          ? `${params.data['Registration Date']}`
          : '--';
      },
    },
    {
      field: 'Show Reservation',
      headerName: t('reservation'),
      sortable: true,
      filter: true,
      cellRenderer: 'ShowReservation',
      suppressMovable: true,
    },
    {
      field: 'name',
      headerName: `${t('userName')} ${t('contractorId')}`,
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.name}` ? `${params.data.name}` : '--';
      },
    },

    {
      field: 'contactPhone',
      headerName: t('contactPhone'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.contactPhone}`
          ? `${params.data.contactPhone}`
          : '--';
      },
    },

    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
  ];

  const tabcolumnDefs = [
    {
      field: 'contactPersonName',
      headerName: t('contactPersonName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `<b>${params.data.contactPersonName}</b> <br /> ${params.data.contactEmail}`;
      },
    },
    {
      field: 'Registration Date',
      headerName: t('registrationDate'),
      sortable: true,
      filter: 'agDateColumnFilter',
      suppressMovable: true,
    },
    {
      field: 'Show Reservation',
      headerName: t('reservation'),
      sortable: true,
      filter: true,
      cellRenderer: 'ShowReservation',
      suppressMovable: true,
    },
    {
      field: 'name',
      headerName: `${t('userName')} ${t('contractorId')}`,
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.name}`;
      },
    },

    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
  ];

  const mobcolumnDefs = [
    {
      field: 'contactPersonName',
      headerName: t('contactPersonName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.contactPersonName}`
          ? `<b>${params.data.contactPersonName}</b> <br /> ${params.data.contactEmail}`
          : '--';
      },
    },
    {
      field: 'Show Reservation',
      headerName: t('Reservation'),
      sortable: true,
      filter: true,
      cellRenderer: 'ShowReservation',
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 80,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  const handleViewMore = (data) => {
    console.log('data', data);
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  const ResendVerification = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/resetPassword`, {
          userData: { email: el.Email, resendLink: true },
          companyId: localStorage.getItem('companyId'),
        })
    );
    if (res?.sent) {
      setTimeout(() => {
        Dialog({
          title: t('resendMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            //setDummy(true);
          },
        });
      }, 150);
    }
  };

  const CopyDataUrl = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/copyUrl`, {
          userData: { email: el.Email },
        })
    );
    setResendData(res);
    setCopyData(true);
  };
  const handleCloseUrl = () => {
    setCopyData(false);
    setCopied(false);
  };
  const handleConfirmation = (userId, status = 5) => {
    setConfirmation(!confirmation);
    setDeleteId(userId);
  };
  const lang = localStorage.getItem('language');
  const [newRoles, setNewRoles] = useState();
  useEffect(() => {
    if (user.roleId === 1) {
      setNewRoles(Roles);
    } else if (user.roleId !== 1) {
      let aa = Roles.filter((el) => el.id !== 1);
      setNewRoles(aa);
    }
  }, [user]);

  const handleShowReservation = (e) => {
    history.push(`/reservationManagement?contractor=${e.data.userId}`);
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setcolumns(tabcolumnDefs);
    } else if (window.innerWidth <= 480) {
      setcolumns(mobcolumnDefs);
    } else {
      setcolumns(columnDefs);
    }
  }, []);

  return (
    <div className="global-data-table contractor-management-page">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-input">
            <input
              type="text"
              id="filter-text-box"
              placeholder={t('freeTextSearch')}
              onInput={onFilterTextBoxChanged}
            />
            <img src={search} alt="search" />
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{usersToDisplay?.length}</span>
              </p>
            </div>
            <div className="actions">
              <img src={file} alt="add" onClick={() => onBtnExport()} />
              {user.roleId !== 10 && (
                <img
                  src={adduser}
                  alt="add"
                  onClick={() => handleOpen('add')}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            columnDefs={columns}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            frameworkComponents={{
              PopupCellRenderer: (e) => (
                <PopupCellRenderer
                  handleOpen={handleOpen}
                  e={e}
                  setUpdate={setUpdate}
                  setPayload={setPayload}
                  setIsEdit={setIsEdit}
                  handleDeleteUser={handleDeleteUser}
                  t={t}
                  kitchensIds={kitchensIds}
                  setKitchensIds={setKitchensIds}
                  Kitchens={Kitchens}
                  Roles={Roles}
                  ResendVerification={ResendVerification}
                  UserStatuses={UserStatuses}
                  CopyDataUrl={CopyDataUrl}
                  handleConfirmation={handleConfirmation}
                />
              ),
              ShowReservation: (e) => (
                <ShowReservation
                  e={e}
                  handleShowReservation={handleShowReservation}
                  t={t}
                />
              ),
              ViewMore: (e) => (
                <ViewMore e={e} handleViewMore={handleViewMore} />
              ),
              // CreditCard: (e) => <CreditCard />,
            }}
            debug={false}
            overlayLoadingTemplate={'<p></p>'}
            // rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            // pagination={true}
            // paginationPageSize={10}
            // paginationNumberFormatter={function (params) {
            //   return '[' + params.value.toLocaleString() + ']';
            // }}
          ></AgGridReact>
        </div>
        {/* <div className="pagination-header">
        {t('pageSize')}:
        <select onChange={() => onPageSizeChanged()} id="page-size">
          <option value="10" selected={true}>
            10
          </option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> */}
        <AddContractorPopup
          fieldNamesSettings={fieldNamesSettings}
          open={open}
          handleClose={handleClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          // userData={{ name: '' }}
          userData={prefetchData}
          newUserFlag={true}
          handleAddNewUser={handleAddNewUser}
          isEdit={true}
          payload={payload}
          setPayload={setPayload}
          handleUpdateUser={handleUpdateUser}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          rolesIds={rolesIds}
          setRolesIds={setRolesIds}
          t={t}
          i18n={i18n}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          setErrors={setErrors}
          errors={errors}
          user={user}
          newRoles={newRoles}
          cibusEnabled={cibusEnabled}
        />
        <CopyUrlUser
          open={copyData}
          handleClose={CopyDataUrl}
          resendData={resendData}
          handleCloseUrl={handleCloseUrl}
          copied={copied}
          setCopied={setCopied}
        />
        <ConfirmationPopup
          open={confirmation}
          handleClose={() => setConfirmation(false)}
          handleDeleteUser={handleDeleteUser}
          userStatus={userStatus}
        />
        <ContractDetails
          fieldNamesSettings={fieldNamesSettings}
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
          handleOpen={handleOpen}
          handleConfirmation={handleConfirmation}
          setPayload={setPayload}
          payload={payload}
          initialState={initialState}
          cibusEnabled={cibusEnabled}
          user={user}
        />
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const ActionButton = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
}) => {
  setUpdate(e.data);
  setIsEdit(true);

  return (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
    >
      <button
        onClick={() => {
          setPayload({
            name: e.data['name'],
            userId: e.data['userId'],
            companyNumber: e.data['companyNumber'],
            contactEmail: e.data['contactEmail'],
            contactPersonName: e.data['contactPersonName'],
            contactPhone: e.data['contactPhone']
              ? e.data['contactPhone'].split('-').join('')
              : e.data['contactPhone'],
            isInvoice: e.data['isInvoice'],
            magneticStripe: e.data['magneticStripe'],
          });
          handleOpen();
        }}
        className="edit-btn"
      >
        {t('editbutton')}
      </button>
      <button
        className="delete-btn"
        onClick={() => handleConfirmation(e.data['userId'])}
      >
        {t('deletebutton')}
      </button>
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button
          className="resend-btn"
          onClick={() => ResendVerification(e.data)}
        >
          {t('resend')}
        </button>
      ) : (
        ''
      )}
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button className="copy-btn" onClick={() => CopyDataUrl(e.data)}>
          {t('copyUrl')}
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

const ShowReservation = ({ e, handleShowReservation, t }) => {
  return (
    <div className="show-reservation">
      <h6 className="edit-btn" onClick={() => handleShowReservation(e)}>
        {t('showReservation')}
      </h6>
    </div>
  );
};

// const CreditCard = () => {
//   return (
//     <div className={`credit-card-option`}>
//       <input type="checkbox" />
//     </div>
//   );
// };

const PopupCellRenderer = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
}) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
      style={{ position: 'relative' }}
    >
      <div className="menu-container">
        <div className="menu-item">
          <div
            onClick={() => {
              setPayload({
                name: e.data['name'],
                userId: e.data['userId'],
                companyNumber: e.data['companyNumber'],
                contactEmail: e.data['contactEmail'],
                name: e.data['name'],
                contactPersonName: e.data['contactPersonName'],
                contactPhone: e.data['contactPhone']
                  ? e.data['contactPhone'].split('-').join('')
                  : e.data['contactPhone'],
                isInvoice: e.data['isInvoice'],
                magneticStripe: e.data['magneticStripe'],
              });
              handleOpen();
            }}
          >
            {t('editbutton')}
          </div>
        </div>
        <div className="menu-item">
          <div onClick={() => handleConfirmation(e.data['userId'])}>
            {t('deletebutton')}
          </div>
        </div>

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div
              className="resend-btn"
              onClick={() => ResendVerification(e.data)}
            >
              {t('resend')}
            </div>
          </div>
        ) : (
          ''
        )}

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div onClick={() => CopyDataUrl(e.data)}>{t('copyUrl')}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      });
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] });
    }

    if (option === 'edit') {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: 'make',
      });
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <img className="more-info" src={more} onClick={visible ? hide : show} />
    </Tippy>
  );
};
const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  languages: state.languages.options,
  currentLanguage: state.languages.current,
  cibusEnabled: state.companyConfig.paymentsSettings.cibusEnabled,
  fieldNamesSettings: state.companyConfig.fieldNamesSettings,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractorManagement);
