import React, { useEffect, useState } from 'react';
import '../../style/myOrders.scss';
import TableBtn from './TableBtn';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import http from '../../services/http';
import { sortOrders } from '../../services/sortItems';

import coins from '../../assets/icons/coinsIcon.svg';
import dinner from '../../assets/icons/dinnerIcon.svg';
import cancelPoints from '../../assets/icons/cancel.svg';
import cancel from '../../assets/icons/cancel.png';
import edit from '../../assets/icons/editIcon.svg';
import voucher from '../../assets/icons/voucherIcon.svg';
import voucherRealized from '../../assets/icons/voucherIconRealized.svg';
import closeIcon from '../../assets/images/close.svg';
import arrow from '../../assets/icons/angle-right-black.svg';

import { StaticStars } from '../../components/RatingStars';
import reviewImg from '../../assets/icons/review.png';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import { useFetchingLoader } from '../../store/app/actions';
import cibusImg from '../../assets/icons/cibus.png';
import { ActiveButton } from '../../components/WE-Button';
import enums from '../../services/enums';

import {
  Modal,
  Box,
  Grid,
  styled,
  Typography,
  Paper,
  Portal,
} from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    padding: '16px 8px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '12px 8px',
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '0',
  p: 4,
  borderRadius: '5px',
  maxWidth: '600px',
  width: '100%',
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MealDetailsPopup = ({
  myOrdersMob,
  handleMyOrdersMob,
  handleMyOrdersMobClose,
  handleVoucherModal,
  mobOderData,
  t,
  OrderStatusesMap,
  MealTypes,
  setOrders,
  paymentMethod,
  handlePopup,
  paymentKitchenId,
  user,
  revertCollectPoints,
  kitchens,
  handleRefresh,
  setMyOrdersMob,
  getCollectPoints,
  nextMealClicked,
  cancelOrderDialog,
  width,
  height,
  startAnim,
  setStartAnim,
  kitchen,
  i18n,
  isUserWithoutWallet,
  setDummy,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const langId =
    localStorage.getItem('languageId') ??
    enums.enums.Languages.find(
      (el) => el.value == localStorage.getItem('defaultLang')
    ).id;
  let history = useHistory();

  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type };
  }, {});
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  let meal = {};
  let dishIds = [];
  let dish = {};
  if (mobOderData) {
    const mealDish = mobOderData?.order
      ? mobOderData?.order?.orderMealDishes[0].mealDish
      : [];
    if (mealDish?.length > 0) {
      meal =
        (mobOderData &&
          mobOderData?.order?.orderMealDishes &&
          mobOderData?.order?.orderMealDishes?.length > 0 &&
          mobOderData?.order?.orderMealDishes[0]?.mealDish?.meal) ??
        {};
    } else {
      meal = mealDish?.meal ?? {};
    }

    dish = mealDish?.dish ?? {};
    const dishArray =
      mobOderData &&
      mobOderData?.order?.orderMealDishes &&
      mobOderData?.order?.orderMealDishes.length > 0 &&
      mobOderData?.order?.orderMealDishes;

    if (dishArray?.length > 0) {
      const a = dishArray?.map((el) => {
        dishIds.push(el?.mealDish?.dishId);
      });
    }
  }
  useEffect(() => {
    console.log('mobOderData', mobOderData);
    22;
  }, [mobOderData]);
  const cibusPayment = () => {
    SwalDialogs.Dialog({
      title: t('cibusPayment'),
      text: t('cibusPaymentAlert'),
      confirmButtonText: t('redirectToCibusSite'),
      buttons: {
        confirmButtonText: t('redirectToCibusSite'),
        denyButtonText: t('payByOneTimeCode'),
        cancelButtonText: t('back'),
        showCancelButton: false,
        showDenyButton: true,
      },
      showCloseButton: true,
      denyButtonColor: '#0060ca',
      closeBtnStyle: {},
      onDenied: async () => {
        SwalDialogs.DialogWithInputAndErrorMess({
          title: `${t('enterOneTimeCode')}`,
          type: 'number',
          onDidOpen: () => {
            const input = document.getElementById('swal-input1');
            input.addEventListener('input', () => {
              document.getElementById('swal-error').style.display = 'none';
            });
          },
          onPreConfirm: () => {
            const input = document.getElementById('swal-input1').value;
            if (!input || input.length !== 5) {
              document.getElementById('swal-error').style.display = 'block';
              document.getElementById('swal-error').innerText = t(
                'enterValidOneTimeCode'
              );
              return false;
            } else {
              return input;
            }
          },
          onConfirm: async (data) => {
            try {
              const res = await http.post(`users/paymentByOneTimeCode`, {
                oneTimeCode: data.value,
                price: mobOderData?.order.orderPrice,
                kitchenId: mobOderData?.kitchenId,
                orderId: mobOderData?.order.id,
              });
              if (res && res.status === 'OK') {
                SwalDialogs.Dialog({
                  title: t('orderWasPaid'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    showConfirmButton: true,
                  },
                  onConfirm:
                    width < 481 &&
                    (mobOderData?.order || mobOderData?.collectPoint) &&
                    myOrdersMob
                      ? () => {
                          setStartAnim(true);
                          setTimeout(() => {
                            document.body.style.overflow = 'unset';
                            handleMyOrdersMobClose();
                            setTabIndex(0);
                            setStartAnim(false);
                          }, 290);
                          setDummy((prev) => !prev);
                        }
                      : () => {
                          handleMyOrdersMobClose();
                          setDummy((prev) => !prev);
                        },
                });
              } else if (res && res.status === 'Try again') {
                SwalDialogs.Dialog({
                  title: 'Try again',
                  type: 'warning',
                  buttons: {
                    showCancelButton: false,
                    showConfirmButton: true,
                  },
                });
              } else {
                SwalDialogs.Dialog({
                  title: res,
                  type: 'warning',
                  buttons: {
                    showCancelButton: false,
                    showConfirmButton: true,
                  },
                });
              }
            } catch (e) {
              SwalDialogs.Dialog({
                title: e,
                type: 'error',
                buttons: {
                  showCancelButton: false,
                  showConfirmButton: false,
                },
                onConfirm:
                  width < 481 &&
                  (mobOderData?.order || mobOderData?.collectPoint) &&
                  myOrdersMob
                    ? () => {
                        setStartAnim(true);
                        setTimeout(() => {
                          document.body.style.overflow = 'unset';
                          handleMyOrdersMobClose();
                          setTabIndex(0);
                          setStartAnim(false);
                        }, 290);
                        setDummy((prev) => !prev);
                      }
                    : () => {
                        handleMyOrdersMobClose();
                        setDummy((prev) => !prev);
                      },
              });
            }
          },
          buttons: {
            confirmButtonText: t('submitLabel'),
            cancelButtonText: t('back'),
          },
        });
      },
      onConfirm: handleCibusPayment,
    });
  };
  const handleCibusPayment = async () => {
    const res = await http.post(`users/userPaymentByCibus`, {
      orderId: mobOderData.order.id,
      price: mobOderData.order.orderPrice,
      paymentOptionId: paymentMethod,
      kitchenId: mobOderData.kitchenId,
    });
    window.location.href = res.url;
  };
  return width < 481 &&
    (mobOderData?.order || mobOderData?.collectPoint) &&
    myOrdersMob ? (
    <>
      <div className={`bottom-popup-container`}>
        <div className={`bottom-popup ${startAnim ? 'closed' : ''}`}>
          <div className="popup-content popup-content-meal-details">
            <div className="lobby-mobile-filter-header">
              <p className="lobby-mobile-filter-header-text">
                {t('mealDetails')}
              </p>
              <span
                onClick={() => {
                  setStartAnim(true);
                  setTimeout(() => {
                    document.body.style.overflow = 'unset';
                    handleMyOrdersMobClose();
                    setTabIndex(0);
                    setStartAnim(false);
                  }, 290);
                }}
                className="lobby-mobile-filter-header-close-btn"
              >
                {t('close')}
              </span>
            </div>
            <div className="line"></div>
            <div className="lobby-mobile-content-wrapper">
              <div>
                {mobOderData?.order ? (
                  <p className="meal-details-popup-meal-desc">
                    {mobOderData?.day} | {mobOderData?.date} |{' '}
                    {mobOderData?.mealType?.display} |{' '}
                    {kitchens
                      ? kitchens.find((el) => el.id == mobOderData?.kitchenId)
                          ?.name
                      : ''}
                  </p>
                ) : (
                  ''
                )}
                <div
                  className={`lobby-mobile-filter-content-wrapper ${
                    tabIndex === 1 ? 'pl-0 pr-0' : ''
                  }`}
                >
                  <div className="meal-details">
                    {mobOderData?.order ? (
                      <Box sx={{ width: '100%' }}>
                        <Box
                          sx={{ borderBottom: 1, borderColor: 'divider' }}
                          className={
                            tabIndex === 1 ? ' padding-left-right-25' : ''
                          }
                        >
                          <Tabs
                            value={tabIndex}
                            onChange={handleChangeTab}
                            aria-label="basic tabs example"
                          >
                            <Tab
                              label={t('generalInfo')}
                              {...a11yProps(0)}
                              className="tab-fonts"
                            />
                            (
                            <Tab
                              label={t('dishes')}
                              {...a11yProps(1)}
                              className="tab-fonts"
                            />
                            )
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={tabIndex} index={0}>
                          <div
                            className="meal-details-popup-first-tab meal-details-popup-first-tab-max-height"
                            style={{
                              maxHeight: height - 390,
                              overflow: 'auto',
                            }}
                          >
                            <div className="meal-details-popup-text-container">
                              <p className="meal-details-popup-font-title">
                                {t('date')}
                              </p>
                              <p className="meal-details-popup-font-content">{`${moment(
                                mobOderData?.date
                              ).format('DD/MM')} ${
                                moment(mobOderData?.date).format(
                                  'YYYY-MM-DD'
                                ) === moment(new Date()).format('YYYY-MM-DD')
                                  ? t('today')
                                  : moment(mobOderData?.date).format(
                                        'YYYY-MM-DD'
                                      ) ==
                                      moment(new Date())
                                        .add(1, 'd')
                                        .format('YYYY-MM-DD')
                                    ? t('tomorrow')
                                    : moment(mobOderData?.date).format('dddd')
                              }`}</p>
                            </div>
                            <div className="meal-details-popup-text-container">
                              <p className="meal-details-popup-font-title">
                                {t('kitchen')}
                              </p>
                              <p className="meal-details-popup-font-content">
                                {kitchens
                                  ? kitchens.find(
                                      (k) => k.id === mobOderData?.kitchenId
                                    )?.name
                                  : ''}
                              </p>
                            </div>

                            <div className="meal-details-popup-text-container">
                              <p className="meal-details-popup-font-title">
                                {t('type')}
                              </p>
                              <p className="meal-details-popup-font-content">
                                {MealTypesMap[mobOderData?.mealTypeId]?.display}
                              </p>
                            </div>
                            <div className="meal-details-popup-text-container">
                              <p className="meal-details-popup-font-title">
                                {t('dishQuantity')}
                              </p>
                              <p className="meal-details-popup-font-content">
                                {mobOderData?.order?.orderMealDishes
                                  ? mobOderData?.order?.orderMealDishes.reduce(
                                      (acc, curr) => acc + curr?.quantity,
                                      0
                                    )
                                  : 0}
                              </p>
                            </div>
                            {mobOderData?.order ? (
                              <div className="meal-details-popup-text-container">
                                <p className="meal-details-popup-font-title">
                                  {t('isChefMealDish')}
                                </p>
                                <p className="meal-details-popup-font-content">
                                  {mobOderData?.lastRealizedOrder.filter(
                                    (elem) =>
                                      elem.orderMealDishes.find(
                                        (ele) => ele.mealDish.isChefMealDish
                                      )
                                  ) > 0
                                    ? t('yes')
                                    : t('no')}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {mobOderData?.order?.paymentOptionId == 4 ? (
                              '--'
                            ) : (
                              <div className="meal-details-popup-text-container">
                                <p className="meal-details-popup-font-title">
                                  {t('totalPrice')}
                                </p>
                                <p className="meal-details-popup-font-content">
                                  {(mobOderData?.order?.paymentOptionId &&
                                    mobOderData?.order?.paymentOptionId == 5) ||
                                  kitchen?.kitchenPayment?.paymentOptionId == 5
                                    ? ''
                                    : '₪'}
                                  {mobOderData?.order?.orderMealDishes
                                    ? mobOderData?.order?.orderMealDishes.reduce(
                                        (acc, curr) => acc + curr?.dishPrice,
                                        0
                                      )
                                    : 0}
                                </p>
                              </div>
                            )}
                            {
                              <div className="meal-details-popup-text-container">
                                <p className="meal-details-popup-font-title">
                                  {t('paymentMethod')}
                                </p>
                                <p className="meal-details-popup-font-content">
                                  {mobOderData?.order?.paymentOptionId
                                    ? enums.enums.PaymentOption.find(
                                        (el) =>
                                          el.id ===
                                          mobOderData?.order?.paymentOptionId
                                      )?.display
                                    : mobOderData?.order?.paymentOptionId ===
                                        null
                                      ? t('invoice')
                                      : ''}
                                </p>
                              </div>
                            }
                            <div className="meal-details-popup-text-container">
                              <p className="meal-details-popup-font-title">
                                {t('status')}
                              </p>
                              <p className="meal-details-popup-font-content">
                                {mobOderData?.order?.orderStatusId === 1
                                  ? t('pending')
                                  : mobOderData?.order?.orderStatusId === 2
                                    ? t('realized')
                                    : mobOderData?.order?.orderStatusId === 4
                                      ? t('pickup')
                                      : t('cancelled')}
                              </p>
                            </div>
                            {mobOderData?.order?.orderStatusId === 4 ? (
                              <div className="meal-details-popup-text-container">
                                <p className="meal-details-popup-font-title">
                                  {t('pickupTime')}
                                </p>
                                <p className="meal-details-popup-font-content">
                                  {mobOderData?.order?.pickupTime || '--'}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {mobOderData?.title ? (
                              <div className="meal-details-popup-text-container">
                                <p className="meal-details-popup-font-title">
                                  {t('title')}
                                </p>
                                <p className="meal-details-popup-font-content">
                                  {mobOderData?.title}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {mobOderData?.description ? (
                              <div className="meal-details-popup-text-container">
                                <p className="meal-details-popup-font-title">
                                  {t('description')}
                                </p>
                                <p className="meal-details-popup-font-content">
                                  {mobOderData?.description}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {mobOderData?.notes ? (
                              <div className="meal-details-popup-text-container">
                                <p className="meal-details-popup-font-title">
                                  {t('notes')}
                                </p>
                                <p className="meal-details-popup-font-content">
                                  {mobOderData?.notes}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabIndex} index={1}>
                          <TableContainer
                            component={Paper}
                            sx={{
                              boxShadow: 0,
                              paddingTop: '16px',
                              width: '100%',
                              height: height - 394,
                              overflow: 'auto',
                            }}
                          >
                            <Table aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className={`def-font-style-size meal-details-dish-cell meal-details-custom-popup-headers-fonts ${
                                      i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                    }`}
                                  >
                                    {t('dishes')}
                                  </StyledTableCell>
                                  <StyledTableCell className="def-font-style-size meal-details-custom-popup-headers-fonts">
                                    {t('amount')}
                                  </StyledTableCell>
                                  <StyledTableCell className="def-font-style-size meal-details-custom-popup-headers-fonts">
                                    {t('price')}
                                  </StyledTableCell>
                                  <StyledTableCell className="def-font-style-size meal-details-custom-popup-headers-fonts">
                                    {t('totalPrice')}
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {mobOderData?.order?.orderMealDishes.map(
                                  (row) => (
                                    <StyledTableRow
                                      key={row.mealDish.dish.name}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}
                                      className="tableRowOpenSans"
                                    >
                                      <StyledTableCell
                                        sx={{ maxWidth: '125px' }}
                                        className={`openSansStyle ${
                                          i18n?.language === 'he'
                                            ? 'pr-4'
                                            : 'pl-4'
                                        }`}
                                      >
                                        {row.mealDish.dish.name}
                                      </StyledTableCell>
                                      <StyledTableCell className="openSansStyle">
                                        {row.quantity}
                                      </StyledTableCell>
                                      <StyledTableCell className="openSansStyle">
                                        {mobOderData?.order?.paymentOptionId ==
                                        4
                                          ? ''
                                          : `${
                                              (mobOderData?.order
                                                ?.paymentOptionId &&
                                                mobOderData?.order
                                                  ?.paymentOptionId == 5) ||
                                              kitchen?.kitchenPayment
                                                ?.paymentOptionId == 5
                                                ? ''
                                                : '₪'
                                            }${row.dishPrice / row.quantity}`}
                                      </StyledTableCell>
                                      <StyledTableCell className="openSansStyle">
                                        {mobOderData?.order?.paymentOptionId ==
                                        4
                                          ? ''
                                          : `${
                                              (mobOderData?.order
                                                ?.paymentOptionId &&
                                                mobOderData?.order
                                                  ?.paymentOptionId == 5) ||
                                              kitchen?.kitchenPayment
                                                ?.paymentOptionId == 5
                                                ? ''
                                                : '₪'
                                            }${row.dishPrice}`}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )
                                )}

                                <StyledTableRow
                                  key={'Total'}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                  className="tableRowOpenSans"
                                >
                                  <StyledTableCell
                                    className={`onlyOpenSansFont def-font-style-size meal-details-custom-popup-headers-fonts ${
                                      i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                    }`}
                                  >
                                    {t('total')}
                                  </StyledTableCell>
                                  <StyledTableCell className="onlyOpenSansFont def-font-style-size meal-details-custom-popup-headers-fonts">
                                    {mobOderData?.order?.orderMealDishes
                                      .length !== 0
                                      ? mobOderData?.order?.orderMealDishes.reduce(
                                          (acc, el) =>
                                            acc + Number(el.quantity),
                                          0
                                        )
                                      : 0}
                                  </StyledTableCell>
                                  <StyledTableCell className="openSansStyle meal-details-custom-popup-headers-fonts">
                                    {''}
                                  </StyledTableCell>
                                  <StyledTableCell className="onlyOpenSansFont def-font-style-size meal-details-custom-popup-headers-fonts">
                                    {mobOderData?.order?.paymentOptionId == 4
                                      ? '--'
                                      : `${
                                          (mobOderData?.order
                                            ?.paymentOptionId &&
                                            mobOderData?.order
                                              ?.paymentOptionId == 5) ||
                                          kitchen?.kitchenPayment
                                            ?.paymentOptionId == 5
                                            ? ''
                                            : '₪'
                                        }${
                                          mobOderData?.order?.orderMealDishes
                                            .length !== 0
                                            ? mobOderData?.order?.orderMealDishes.reduce(
                                                (acc, el) =>
                                                  acc + Number(el.dishPrice),
                                                0
                                              )
                                            : 0
                                        }`}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CustomTabPanel>
                      </Box>
                    ) : mobOderData?.collectPoint ? (
                      <div className="meal-details-popup-credit-points">
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('date')}
                          </p>
                          <p className="meal-details-popup-font-content">{`${moment(
                            mobOderData?.date
                          ).format('DD/MM')} ${
                            moment(new Date()).format('YYYY-MM-DD') ===
                            moment(mobOderData?.date).format('YYYY-MM-DD')
                              ? t('today')
                              : moment(mobOderData?.date).format(
                                    'YYYY-MM-DD'
                                  ) ==
                                  moment(new Date())
                                    .add(1, 'd')
                                    .format('YYYY-MM-DD')
                                ? t('tomorrow')
                                : moment(mobOderData?.date).format('dddd')
                          }`}</p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('type')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {MealTypesMap[mobOderData?.mealTypeId]?.display}
                          </p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('kitchen')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {kitchens
                              ? kitchens.find(
                                  (k) => k.id === mobOderData?.kitchenId
                                )?.name
                              : ''}
                          </p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('creditPoints')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {mobOderData?.collectPoint.point}
                          </p>
                        </div>
                        <div className="meal-details-popup-text-container ">
                          <p className="meal-details-popup-font-title">
                            {t('status')}
                          </p>
                          <div className="meal-details-popup-font-content meal-details-popup-font-content-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <circle
                                cx="9"
                                cy="9"
                                r="9"
                                fill={
                                  mobOderData?.collectPoint.status
                                    ? '#00D100'
                                    : '#2196F3'
                                }
                              />
                            </svg>
                            <p className="m-0">
                              {mobOderData?.collectPoint.status
                                ? t('approvedPointMsg')
                                : t('pending')}
                            </p>
                          </div>
                        </div>
                        {mobOderData?.title ? (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('title')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {mobOderData?.title}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {mobOderData?.description ? (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('description')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {mobOderData?.description}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {mobOderData?.notes ? (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('notes')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {mobOderData?.notes}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('date')}
                          </p>
                          <p className="meal-details-popup-font-content">{`${moment(
                            mobOderData?.date
                          ).format('DD/MM')} ${
                            moment(new Date()).format('YYYY-MM-DD') ===
                            moment(mobOderData?.date).format('YYYY-MM-DD')
                              ? t('today')
                              : moment(mobOderData?.date).format(
                                    'YYYY-MM-DD'
                                  ) ==
                                  moment(new Date())
                                    .add(1, 'd')
                                    .format('YYYY-MM-DD')
                                ? t('tomorrow')
                                : moment(mobOderData?.date).format('dddd')
                          }`}</p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('type')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {MealTypesMap[mobOderData?.mealTypeId]?.display}
                          </p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('kitchen')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {kitchens
                              ? kitchens.find(
                                  (k) => k.id === mobOderData?.kitchenId
                                )?.name
                              : ''}
                          </p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('title')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {mobOderData?.title}
                          </p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('notes')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {mobOderData?.notes}
                          </p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('description')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {mobOderData?.description}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {user.roleId !== 7 ? (
                <div className="meal-details-btn-section">
                  <>
                    {mobOderData?.collectPoint &&
                    !(
                      mobOderData &&
                      user.contractors &&
                      Array.isArray(user.contractors) &&
                      user.contractors[0]?.cutOffTime &&
                      user.contractors[0]?.cutOffTime &&
                      !moment().isBefore(
                        moment(
                          mobOderData.date +
                            ' ' +
                            mobOderData.mealType.from +
                            ':00'
                        ).subtract(user.contractors[0]?.cutOffTime, 'minutes')
                      )
                    ) ? (
                      <TableBtn
                        customClass={'font-for-buttons-in-card'}
                        imgSize={'img-resize'}
                        img={cancelPoints}
                        btnName={t('cancel')}
                        func={() => {
                          isUserWithoutWallet
                            ? SwalDialogs.Dialog({
                                title: `${t('userWithoutWallet')}`,
                                type: 'error',
                                buttons: {
                                  showCancelButton: true,
                                  showConfirmButton: false,
                                },
                              })
                            : revertCollectPoints(mobOderData);
                        }}
                      />
                    ) : mobOderData?.order ? (
                      <>
                        {!(
                          mobOderData &&
                          user.contractors &&
                          Array.isArray(user.contractors) &&
                          user.contractors[0]?.cutOffTime &&
                          user.contractors[0]?.cutOffTime &&
                          !moment().isBefore(
                            moment(
                              mobOderData.date +
                                ' ' +
                                mobOderData.mealType.from +
                                ':00'
                            ).subtract(
                              user.contractors[0].cutOffTime,
                              'minutes'
                            )
                          )
                        ) &&
                        !(
                          mobOderData?.order?.reported &&
                          mobOderData?.order?.paymentStatus
                        ) ? (
                          <TableBtn
                            customClass={'font-for-buttons-in-card'}
                            imgSize={'img-resize'}
                            img={cancelPoints}
                            btnName={t('cancel')}
                            func={() => cancelOrderDialog(mobOderData.order)}
                          />
                        ) : (
                          ''
                        )}
                        {mobOderData?.order?.paymentOptionId === 6 &&
                        mobOderData?.order?.paymentStatus === false ? (
                          <TableBtn
                            customClass={'font-for-buttons-in-card'}
                            imgSize={'img-resize'}
                            img={cibusImg}
                            btnName={t('payment')}
                            func={() => {
                              document.body.style.overflow = 'auto';
                              cibusPayment();
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {!(
                          mobOderData &&
                          user.contractors &&
                          Array.isArray(user.contractors) &&
                          user.contractors[0].cutOffTime &&
                          user.contractors[0].cutOffTime &&
                          !moment().isBefore(
                            moment(
                              mobOderData.date +
                                ' ' +
                                mobOderData.mealType.from +
                                ':00'
                            ).subtract(
                              user.contractors[0].cutOffTime,
                              'minutes'
                            )
                          )
                        ) &&
                        !(
                          mobOderData?.order?.reported &&
                          mobOderData?.order?.paymentStatus
                        ) ? (
                          <TableBtn
                            customClass={'font-for-buttons-in-card'}
                            imgSize={'img-resize'}
                            img={edit}
                            btnName={t('edit')}
                            func={() => {
                              document.body.style.overflow = 'auto';
                              history.push({
                                pathname: `/orderSummary`,
                                state: {
                                  date: meal?.date,
                                  mealType: meal?.mealTypeId,
                                  slotId: mobOderData?.mealSlotId,
                                  orderId: mobOderData?.order.id,
                                  description: meal?.description,
                                  title: meal?.title,
                                  notes: meal?.notes,
                                  dishId: dishIds[0],
                                  dishIds: dishIds,
                                },
                              });
                            }}
                          />
                        ) : (
                          ''
                        )}

                        <TableBtn
                          customClass={'font-for-buttons-in-card-voucher'}
                          imgSize={'img-resize-for-voucher'}
                          img={voucher}
                          btnName={t('voucher')}
                          func={() => {
                            document.body.style.overflow = 'auto';
                            handleVoucherModal(mobOderData);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {mobOderData?.mealType?.creditPoints &&
                        !mobOderData.isOrdered &&
                        user.roleId !== 6 &&
                        !(
                          mobOderData &&
                          user.contractors &&
                          Array.isArray(user.contractors) &&
                          user.contractors[0].cutOffTime &&
                          user.contractors[0].cutOffTime &&
                          !moment().isBefore(
                            moment(
                              mobOderData.date +
                                ' ' +
                                mobOderData.mealType.from +
                                ':00'
                            ).subtract(
                              user.contractors[0].cutOffTime,
                              'minutes'
                            )
                          )
                        ) ? (
                          <TableBtn
                            customClass={'font-for-buttons-in-card'}
                            imgSize={'img-resize'}
                            img={coins}
                            btnName={t('collectPoints')}
                            func={() => {
                              isUserWithoutWallet
                                ? SwalDialogs.Dialog({
                                    title: `${t('userWithoutWallet')}`,
                                    type: 'error',
                                    buttons: {
                                      showCancelButton: true,
                                      showConfirmButton: false,
                                    },
                                  })
                                : getCollectPoints(mobOderData);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {!(
                          mobOderData &&
                          user.contractors &&
                          Array.isArray(user.contractors) &&
                          user.contractors[0].cutOffTime &&
                          user.contractors[0].cutOffTime &&
                          !moment().isBefore(
                            moment(
                              mobOderData.date +
                                ' ' +
                                mobOderData.mealType.from +
                                ':00'
                            ).subtract(
                              user.contractors[0].cutOffTime,
                              'minutes'
                            )
                          )
                        ) ? (
                          <TableBtn
                            img={dinner}
                            customClass={'font-for-buttons-in-card'}
                            imgSize={'img-resize'}
                            btnName={t('order')}
                            func={() => {
                              document.body.style.overflow = 'auto';
                              nextMealClicked(mobOderData);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {mobOderData?.lastRealizedOrder ? (
                          <TableBtn
                            customClass={'font-for-buttons-in-card-voucher'}
                            imgSize={'img-resize-for-voucher'}
                            img={voucherRealized}
                            btnName={t('realizedVoucher')}
                            func={() => {
                              document.body.style.overflow = 'auto';
                              handleVoucherModal(mobOderData);
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Portal>
      <Modal
        open={myOrdersMob}
        fullScreen={false}
        disableEnforceFocus
        disableScrollLock={true}
        className="view-more-popup view-more-popup-lobby-iphone-fix"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-popup-lobby-iphone-fix">
          <div className="meal-details-modal">
            <div
              className={`meal-details-popup-content-wrapper ${
                tabIndex === 1 ? 'pl-0 pr-0' : ''
              }`}
            >
              <div
                className={`meal-details-popup-heading${
                  tabIndex === 1 ? ' padding-left-right' : ''
                }${mobOderData?.order ? '' : ' meal-details-popup-heading-pb'}`}
              >
                <h6 className="meal-details-popup-heading-h6">
                  {t('mealDetails')}
                </h6>
                <div className="meal-details-popup-heading-close-container">
                  <img
                    className="meal-details-popup-heading-close"
                    src={closeIcon}
                    alt="close"
                    onClick={() => {
                      handleMyOrdersMobClose();
                      setTabIndex(0);
                    }}
                  />
                </div>
              </div>
              {mobOderData?.order ? (
                <p className="meal-details-popup-meal-desc">
                  {mobOderData?.day} | {mobOderData?.date} |{' '}
                  {mobOderData?.mealType?.display} |{' '}
                  {kitchens
                    ? kitchens.find((el) => el.id == mobOderData?.kitchenId)
                        ?.name
                    : ''}
                </p>
              ) : (
                ''
              )}
              <div className="meal-details">
                {mobOderData?.order ? (
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{ borderBottom: 1, borderColor: 'divider' }}
                      className={tabIndex === 1 ? ' padding-left-right-25' : ''}
                    >
                      <Tabs
                        value={tabIndex}
                        onChange={handleChangeTab}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label={t('generalInfo')}
                          {...a11yProps(0)}
                          className="tab-fonts"
                        />
                        (
                        <Tab
                          label={t('dishes')}
                          {...a11yProps(1)}
                          className="tab-fonts"
                        />
                        )
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={tabIndex} index={0}>
                      <div className="meal-details-popup-first-tab">
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('date')}
                          </p>
                          <p className="meal-details-popup-font-content">{`${moment(
                            mobOderData?.date
                          ).format('DD/MM')} ${
                            moment(mobOderData?.date).format('YYYY-MM-DD') ===
                            moment(new Date()).format('YYYY-MM-DD')
                              ? t('today')
                              : moment(mobOderData?.date).format(
                                    'YYYY-MM-DD'
                                  ) ==
                                  moment(new Date())
                                    .add(1, 'd')
                                    .format('YYYY-MM-DD')
                                ? t('tomorrow')
                                : moment(mobOderData?.date).format('dddd')
                          }`}</p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('kitchen')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {kitchens
                              ? kitchens.find(
                                  (k) => k.id === mobOderData?.kitchenId
                                )?.name
                              : ''}
                          </p>
                        </div>

                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('type')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {MealTypesMap[mobOderData?.mealTypeId]?.display}
                          </p>
                        </div>
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('dishQuantity')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {mobOderData?.order?.orderMealDishes
                              ? mobOderData?.order?.orderMealDishes.reduce(
                                  (acc, curr) => acc + curr?.quantity,
                                  0
                                )
                              : 0}
                          </p>
                        </div>
                        {mobOderData?.order ? (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('isChefMealDish')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {Boolean(
                                mobOderData?.order.orderMealDishes.find(
                                  (el) => el.mealDish.isChefMealDish
                                )
                              )
                                ? t('yes')
                                : t('no')}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {mobOderData?.order?.paymentOptionId == 4 ? (
                          ''
                        ) : (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('totalPrice')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {(mobOderData?.order?.paymentOptionId &&
                                mobOderData?.order?.paymentOptionId == 5) ||
                              kitchen?.kitchenPayment?.paymentOptionId == 5
                                ? ''
                                : '₪'}
                              {mobOderData?.order?.orderMealDishes
                                ? mobOderData?.order?.orderMealDishes.reduce(
                                    (acc, curr) => acc + curr?.dishPrice,
                                    0
                                  )
                                : 0}
                            </p>
                          </div>
                        )}
                        {
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('paymentMethod')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {mobOderData?.order?.paymentOptionId
                                ? enums.enums.PaymentOption.find(
                                    (el) =>
                                      el.id ===
                                      mobOderData?.order?.paymentOptionId
                                  )?.display
                                : mobOderData?.order?.paymentOptionId === null
                                  ? t('invoice')
                                  : ''}
                            </p>
                          </div>
                        }
                        <div className="meal-details-popup-text-container">
                          <p className="meal-details-popup-font-title">
                            {t('status')}
                          </p>
                          <p className="meal-details-popup-font-content">
                            {mobOderData?.order?.orderStatusId === 1
                              ? t('pending')
                              : mobOderData?.order?.orderStatusId === 2
                                ? t('realized')
                                : mobOderData?.order?.orderStatusId === 4
                                  ? t('pickup')
                                  : t('cancelled')}
                          </p>
                        </div>
                        {mobOderData?.order?.orderStatusId === 4 ? (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('pickupTime')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {mobOderData?.order?.pickupTime || '--'}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {mobOderData?.title ? (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('title')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {mobOderData?.title}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {mobOderData?.description ? (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('description')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {mobOderData?.description}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {mobOderData?.notes ? (
                          <div className="meal-details-popup-text-container">
                            <p className="meal-details-popup-font-title">
                              {t('notes')}
                            </p>
                            <p className="meal-details-popup-font-content">
                              {mobOderData?.notes}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndex} index={1}>
                      <TableContainer
                        component={Paper}
                        sx={{
                          boxShadow: 0,
                          paddingTop: '16px',
                          width: '100%',
                          maxHeight: height - 354,
                          overflow: 'auto',
                        }}
                      >
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell
                                className={`def-font-style-size meal-details-dish-cell ${
                                  i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                }`}
                              >
                                {t('dishes')}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className="def-font-style-size"
                              >
                                {t('amount')}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className="def-font-style-size"
                              >
                                {t('price')}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className="def-font-style-size"
                              >
                                {t('totalPrice')}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {mobOderData?.order?.orderMealDishes.map((row) => (
                              <StyledTableRow
                                key={row.mealDish.dish.name}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                                className="tableRowOpenSans"
                              >
                                <StyledTableCell
                                  sx={{ maxWidth: '125px' }}
                                  className={`openSansStyle ${
                                    i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                  }`}
                                >
                                  {row.mealDish.dish.name}
                                </StyledTableCell>
                                <StyledTableCell
                                  className={`openSansStyle ${
                                    i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                  }`}
                                >
                                  {row.quantity}
                                </StyledTableCell>
                                <StyledTableCell
                                  className={`openSansStyle ${
                                    i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                  }`}
                                >
                                  {mobOderData?.order?.paymentOptionId == 4
                                    ? '--'
                                    : `${
                                        (mobOderData?.order?.paymentOptionId &&
                                          mobOderData?.order?.paymentOptionId ==
                                            5) ||
                                        kitchen?.kitchenPayment
                                          ?.paymentOptionId == 5
                                          ? ''
                                          : '₪'
                                      }${row.dishPrice / row.quantity}`}
                                </StyledTableCell>
                                <StyledTableCell
                                  className={`openSansStyle ${
                                    i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                  }`}
                                >
                                  {mobOderData?.order?.paymentOptionId == 4
                                    ? '--'
                                    : `${
                                        (mobOderData?.order?.paymentOptionId &&
                                          mobOderData?.order?.paymentOptionId ==
                                            5) ||
                                        kitchen?.kitchenPayment
                                          ?.paymentOptionId == 5
                                          ? ''
                                          : '₪'
                                      }${row.dishPrice}`}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}

                            <StyledTableRow
                              key={'Total'}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                              className="tableRowOpenSans"
                            >
                              <StyledTableCell
                                className={`onlyOpenSansFont def-font-style-size  ${
                                  i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                }`}
                              >
                                {t('total')}
                              </StyledTableCell>

                              <StyledTableCell
                                className={`onlyOpenSansFont def-font-style-size ${
                                  i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                }`}
                              >
                                {mobOderData?.order?.orderMealDishes.length !==
                                0
                                  ? mobOderData?.order?.orderMealDishes.reduce(
                                      (acc, el) => acc + Number(el.quantity),
                                      0
                                    )
                                  : 0}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`openSansStyle ${
                                  i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                }`}
                              >
                                {''}
                              </StyledTableCell>

                              <StyledTableCell
                                className={`onlyOpenSansFont def-font-style-size ${
                                  i18n?.language === 'he' ? 'pr-4' : 'pl-4'
                                }`}
                              >
                                {mobOderData?.order?.paymentOptionId == 4
                                  ? '--'
                                  : `${
                                      (mobOderData?.order?.paymentOptionId &&
                                        mobOderData?.order?.paymentOptionId ==
                                          5) ||
                                      kitchen?.kitchenPayment
                                        ?.paymentOptionId == 5
                                        ? ''
                                        : '₪'
                                    }${
                                      mobOderData?.order?.orderMealDishes
                                        .length !== 0
                                        ? mobOderData?.order?.orderMealDishes.reduce(
                                            (acc, el) =>
                                              acc + Number(el.dishPrice),
                                            0
                                          )
                                        : 0
                                    }`}
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomTabPanel>
                  </Box>
                ) : mobOderData?.collectPoint ? (
                  <>
                    <div className="meal-details-popup-text-container">
                      <p className="meal-details-popup-font-title">
                        {t('date')}
                      </p>
                      <p className="meal-details-popup-font-content">{`${moment(
                        mobOderData?.date
                      ).format('DD/MM')} ${
                        moment(new Date()).format('YYYY-MM-DD') ===
                        moment(mobOderData?.date).format('YYYY-MM-DD')
                          ? t('today')
                          : moment(mobOderData?.date).format('YYYY-MM-DD') ==
                              moment(new Date())
                                .add(1, 'd')
                                .format('YYYY-MM-DD')
                            ? t('tomorrow')
                            : moment(mobOderData?.date).format('dddd')
                      }`}</p>
                    </div>
                    <div className="meal-details-popup-text-container">
                      <p className="meal-details-popup-font-title">
                        {t('type')}
                      </p>
                      <p className="meal-details-popup-font-content">
                        {MealTypesMap[mobOderData?.mealTypeId]?.display}
                      </p>
                    </div>
                    <div className="meal-details-popup-text-container">
                      <p className="meal-details-popup-font-title">
                        {t('kitchen')}
                      </p>
                      <p className="meal-details-popup-font-content">
                        {kitchens
                          ? kitchens.find(
                              (k) => k.id === mobOderData?.kitchenId
                            )?.name
                          : ''}
                      </p>
                    </div>
                    <div className="meal-details-popup-text-container">
                      <p className="meal-details-popup-font-title">
                        {t('creditPoints')}
                      </p>
                      <p className="meal-details-popup-font-content">
                        {mobOderData?.collectPoint.point}
                      </p>
                    </div>
                    <div className="meal-details-popup-text-container ">
                      <p className="meal-details-popup-font-title">
                        {t('status')}
                      </p>
                      <div className="meal-details-popup-font-content meal-details-popup-font-content-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <circle
                            cx="9"
                            cy="9"
                            r="9"
                            fill={
                              mobOderData?.collectPoint.status
                                ? '#00D100'
                                : '#2196F3'
                            }
                          />
                        </svg>
                        <p className="m-0">
                          {mobOderData?.collectPoint.status
                            ? t('approvedPointMsg')
                            : t('pending')}
                        </p>
                      </div>
                    </div>
                    {mobOderData?.title ? (
                      <div className="meal-details-popup-text-container">
                        <p className="meal-details-popup-font-title">
                          {t('title')}
                        </p>
                        <p className="meal-details-popup-font-content">
                          {mobOderData?.title}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {mobOderData?.description ? (
                      <div className="meal-details-popup-text-container">
                        <p className="meal-details-popup-font-title">
                          {t('description')}
                        </p>
                        <p className="meal-details-popup-font-content">
                          {mobOderData?.description}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {mobOderData?.notes ? (
                      <div className="meal-details-popup-text-container">
                        <p className="meal-details-popup-font-title">
                          {t('notes')}
                        </p>
                        <p className="meal-details-popup-font-content">
                          {mobOderData?.notes}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    <div className="meal-details-popup-text-container">
                      <p className="meal-details-popup-font-title">
                        {t('date')}
                      </p>
                      <p className="meal-details-popup-font-content">{`${moment(
                        mobOderData?.date
                      ).format('DD/MM')} ${
                        moment(new Date()).format('YYYY-MM-DD') ===
                        moment(mobOderData?.date).format('YYYY-MM-DD')
                          ? t('today')
                          : moment(mobOderData?.date).format('YYYY-MM-DD') ==
                              moment(new Date())
                                .add(1, 'd')
                                .format('YYYY-MM-DD')
                            ? t('tomorrow')
                            : moment(mobOderData?.date).format('dddd')
                      }`}</p>
                    </div>
                    <div className="meal-details-popup-text-container">
                      <p className="meal-details-popup-font-title">
                        {t('type')}
                      </p>
                      <p className="meal-details-popup-font-content">
                        {MealTypesMap[mobOderData?.mealTypeId]?.display}
                      </p>
                    </div>
                    <div className="meal-details-popup-text-container">
                      <p className="meal-details-popup-font-title">
                        {t('kitchen')}
                      </p>
                      <p className="meal-details-popup-font-content">
                        {kitchens
                          ? kitchens.find(
                              (k) => k.id === mobOderData?.kitchenId
                            )?.name
                          : ''}
                      </p>
                    </div>
                    {mobOderData?.title ? (
                      <div className="meal-details-popup-text-container">
                        <p className="meal-details-popup-font-title">
                          {t('title')}
                        </p>
                        <p className="meal-details-popup-font-content">
                          {mobOderData?.title}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {mobOderData?.description ? (
                      <div className="meal-details-popup-text-container">
                        <p className="meal-details-popup-font-title">
                          {t('description')}
                        </p>
                        <p className="meal-details-popup-font-content">
                          {mobOderData?.description}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    {mobOderData?.notes ? (
                      <div className="meal-details-popup-text-container">
                        <p className="meal-details-popup-font-title">
                          {t('notes')}
                        </p>
                        <p className="meal-details-popup-font-content">
                          {mobOderData?.notes}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </div>
            {user.roleId !== 7 ? (
              <div className="meal-details-btn-section meal-details-btn-section-iphone-fix">
                <>
                  {mobOderData?.collectPoint &&
                  !(
                    mobOderData &&
                    user.contractors &&
                    Array.isArray(user.contractors) &&
                    user.contractors[0].cutOffTime &&
                    user.contractors[0].cutOffTime &&
                    !moment().isBefore(
                      moment(
                        mobOderData.date +
                          ' ' +
                          mobOderData.mealType.from +
                          ':00'
                      ).subtract(user.contractors[0].cutOffTime, 'minutes')
                    )
                  ) ? (
                    <TableBtn
                      customClass={'font-for-buttons-in-card'}
                      imgSize={'img-resize'}
                      img={cancelPoints}
                      btnName={t('cancel')}
                      func={() => {
                        isUserWithoutWallet
                          ? SwalDialogs.Dialog({
                              title: `${t('userWithoutWallet')}`,
                              type: 'error',
                              buttons: {
                                showCancelButton: true,
                                showConfirmButton: false,
                              },
                            })
                          : revertCollectPoints(mobOderData);
                      }}
                    />
                  ) : mobOderData?.order ? (
                    <>
                      {mobOderData?.order &&
                      !(
                        mobOderData &&
                        user.contractors &&
                        Array.isArray(user.contractors) &&
                        user.contractors[0].cutOffTime &&
                        user.contractors[0].cutOffTime &&
                        !moment().isBefore(
                          moment(
                            mobOderData.date +
                              ' ' +
                              mobOderData.mealType.from +
                              ':00'
                          ).subtract(user.contractors[0].cutOffTime, 'minutes')
                        )
                      ) &&
                      !(
                        mobOderData?.order?.reported &&
                        mobOderData?.order?.paymentStatus
                      ) ? (
                        <TableBtn
                          customClass={'font-for-buttons-in-card'}
                          imgSize={'img-resize'}
                          img={cancelPoints}
                          btnName={t('cancel')}
                          func={() => {
                            document.body.style.overflow = 'auto';
                            cancelOrderDialog(mobOderData.order);
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {mobOderData?.order?.paymentOptionId === 6 &&
                      mobOderData?.order?.paymentStatus === false ? (
                        <TableBtn
                          customClass={'font-for-buttons-in-card'}
                          imgSize={'img-resize'}
                          img={cibusImg}
                          btnName={t('payment')}
                          func={() => {
                            document.body.style.overflow = 'auto';
                            cibusPayment();
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {!(
                        mobOderData &&
                        user.contractors &&
                        Array.isArray(user.contractors) &&
                        user.contractors[0].cutOffTime &&
                        user.contractors[0].cutOffTime &&
                        !moment().isBefore(
                          moment(
                            mobOderData.date +
                              ' ' +
                              mobOderData.mealType.from +
                              ':00'
                          ).subtract(user.contractors[0].cutOffTime, 'minutes')
                        )
                      ) &&
                      !(
                        mobOderData?.order?.reported &&
                        mobOderData?.order?.paymentStatus
                      ) ? (
                        <TableBtn
                          customClass={'font-for-buttons-in-card'}
                          imgSize={'img-resize'}
                          img={edit}
                          btnName={t('edit')}
                          func={() => {
                            document.body.style.overflow = 'auto';
                            history.push({
                              pathname: `/orderSummary`,
                              state: {
                                date: meal?.date,
                                mealType: meal?.mealTypeId,
                                slotId: mobOderData?.mealSlotId,
                                orderId: mobOderData?.order.id,
                                description: meal?.description,
                                title: meal?.title,
                                notes: meal?.notes,
                                dishId: dishIds[0],
                                dishIds: dishIds,
                              },
                            });
                          }}
                        />
                      ) : (
                        ''
                      )}

                      <TableBtn
                        customClass={'font-for-buttons-in-card-voucher'}
                        imgSize={'img-resize-for-voucher'}
                        img={voucher}
                        btnName={t('voucher')}
                        func={() => {
                          document.body.style.overflow = 'auto';
                          handleVoucherModal(mobOderData);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {mobOderData?.mealType?.creditPoints &&
                      !mobOderData.isOrdered &&
                      user.roleId !== 6 &&
                      !(
                        mobOderData &&
                        user.contractors &&
                        Array.isArray(user.contractors) &&
                        user.contractors[0].cutOffTime &&
                        user.contractors[0].cutOffTime &&
                        !moment().isBefore(
                          moment(
                            mobOderData.date +
                              ' ' +
                              mobOderData.mealType.from +
                              ':00'
                          ).subtract(user.contractors[0].cutOffTime, 'minutes')
                        )
                      ) ? (
                        <TableBtn
                          customClass={'font-for-buttons-in-card'}
                          imgSize={'img-resize'}
                          img={coins}
                          btnName={t('collectPoints')}
                          func={() => {
                            isUserWithoutWallet
                              ? SwalDialogs.Dialog({
                                  title: `${t('userWithoutWallet')}`,
                                  type: 'error',
                                  buttons: {
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                  },
                                })
                              : getCollectPoints(mobOderData);
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {!(
                        mobOderData &&
                        user.contractors &&
                        Array.isArray(user.contractors) &&
                        user.contractors[0].cutOffTime &&
                        user.contractors[0].cutOffTime &&
                        !moment().isBefore(
                          moment(
                            mobOderData.date +
                              ' ' +
                              mobOderData.mealType.from +
                              ':00'
                          ).subtract(user.contractors[0].cutOffTime, 'minutes')
                        )
                      ) ? (
                        <TableBtn
                          img={dinner}
                          customClass={'font-for-buttons-in-card'}
                          imgSize={'img-resize'}
                          btnName={t('order')}
                          func={() => {
                            document.body.style.overflow = 'auto';
                            nextMealClicked(mobOderData);
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {mobOderData?.lastRealizedOrder ? (
                        <TableBtn
                          customClass={'font-for-buttons-in-card-voucher'}
                          imgSize={'img-resize-for-voucher'}
                          img={voucherRealized}
                          btnName={t('realizedVoucher')}
                          func={() => {
                            document.body.style.overflow = 'auto';
                            handleVoucherModal(mobOderData);
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </>
              </div>
            ) : (
              ''
            )}
          </div>
        </Box>
      </Modal>
    </Portal>
  );
};

export default MealDetailsPopup;
