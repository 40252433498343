import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import http from '../../services/http';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import QRCode from 'qrcode';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
  minHeight: '35vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ViewDetails = ({
  open,
  details,
  handleClose,
  UserStatuses,
  setKitchensIds,
  setPayload,
  Kitchens,
  Roles,
  handleOpen,
  handleConfirmation,
  ResendVerification,
  CopyDataUrl,
  handleWallet,
  handleOrderLink,
  setShowContractor,
  setMealTypesIds,
  setMealTypesKitchenWise,
  getMealTypesKitchenWise,
  getMealTypes,
  cibusEnabled,
  attendanceEnabled,
  fieldNamesSettings,
  countryOptions,
  visitPurposeValues,
  allUsers,
  factories,
  user,
  contractorList,
  useFetchingLoader,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [paymentMethodsForCA, setPaymentMethodsForCA] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const [userQR, setUserQR] = useState('');
  const [timeSpan, setTimeSpan] = useState(null);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }
  const isSubset = (subset, superset) => {
    return subset.every((item) => superset.includes(item));
  };
  useEffect(() => {
    if (details?.RoleId === 7) {
      if (
        details.allContractors &&
        details.allContractors.length !== 0 &&
        Boolean(details.allContractors.find((elem) => elem?.isInvoice === 1))
      ) {
        const methods = details.paymentMethod;
        methods.push('invoice');
        const uniqueArray = Array.from(new Set(methods));
        setPaymentMethodsForCA(uniqueArray.map((el) => t(el)).join(', '));
      } else {
        setPaymentMethodsForCA(
          details.paymentMethod.map((el) => t(el)).join(', ')
        );
      }
    }
  }, [details]);
  const formatTimeObject = (obj) => {
    const units = {
      years: 'year',
      months: 'month',
      weeks: 'week',
      days: 'day',
      hours: 'hour',
      minutes: 'minute',
      seconds: 'second',
    };
    const result = Object.entries(units)
      .map(([key, label]) => {
        const value = obj[key];
        if (value > 0) {
          return `${value} ${label}${value > 1 ? 's' : ''}`;
        }
        return null;
      })
      .filter(Boolean)
      .join(' ');

    return result;
  };
  useEffect(() => {
    const fetchData = async () => {
      await useFetchingLoader(async () => {
        const data = await http.get('audit/getByParams', {
          filter: {
            entity: 'user',
            actionEntityId: details.userId,
          },
        });
        const avgTime = await http.get(
          `users/getAvgOrderTimeByUser/${details?.userId}`
        );
        console.log('avgTime', avgTime);
        if (avgTime) {
          const interval = formatTimeObject(moment.duration(avgTime)?._data);
          console.log('interval', interval);

          setTimeSpan(interval);
        }
        const qr = await QRCode.toDataURL('' + details?.userId);
        setUserQR(qr);
        setHistoryData(data.data);
      });
    };
    if (details?.userId) {
      fetchData();
    }
  }, [details]);
  return (
    <>
      <Modal open={open} className="view-more-popup">
        <Box sx={style}>
          <div className="view-detail-head">
            {/* <Typography id="modal-modal-title" variant="h5" component="h2">
              {t('user')}
            </Typography> */}
            <div className="reservation-title">
              <h5>
                {t('user')}
                {t('reservationNumber')}
                {details?.userId}
              </h5>
              <h6>{details?.Name ? details?.Name : ''}</h6>
            </div>
            <div className="close-btn-view">
              <img
                src={cancel}
                alt="close"
                onClick={() => {
                  handleClose();
                  setTabIndex(0);
                  setTimeSpan(null);
                }}
              />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabIndex}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label={t('mainInfo')} {...a11yProps(0)} />

                <Tab label={t('history')} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabIndex} index={0}>
              <div className="more-detail-popup">
                <div className="user-detail-container">
                  <div className="user-details-row">
                    <div className="view-detail-content">
                      <div className="user-details-block">
                        <h6>{t('QR')}:</h6>
                        <img src={userQR} />
                      </div>
                      <div className="user-details-block">
                        <h6>{t('email')}:</h6>
                        <p>{details?.Email ? details?.Email : '--'}</p>
                      </div>
                      {details?.RoleId !== 8 ? (
                        <>
                          <div className="user-details-block">
                            <h6>
                              {fieldNamesSettings?.find(
                                (el) => el.value === 'employeeId'
                              )
                                ? fieldNamesSettings
                                    ?.find((el) => el.value === 'employeeId')
                                    ?.translations.find(
                                      (el) =>
                                        el.language.shortCode == i18n.language
                                    )?.text
                                : t('employeeNo')}
                              :
                            </h6>
                            <p>
                              {details?.employeeId ? details?.employeeId : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>
                              {fieldNamesSettings?.find(
                                (el) => el.value === 'cardId'
                              )
                                ? fieldNamesSettings
                                    ?.find((el) => el.value === 'cardId')
                                    ?.translations.find(
                                      (el) =>
                                        el.language.shortCode == i18n.language
                                    )?.text
                                : t('cardId')}
                              :
                            </h6>
                            <p>
                              {details?.['Card Number']
                                ? details?.['Card Number']
                                : '--'}
                            </p>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      {details?.RoleId !== 8 && (
                        <div className="user-details-block">
                          <h6>{t('resetPassword')}:</h6>
                          <p>
                            <input
                              type="checkbox"
                              checked={details?.isPasswordTemporary}
                            />
                          </p>
                        </div>
                      )}
                      <div className="user-details-block">
                        <h6>{t('userStatus')}:</h6>
                        <p>{details?.Status ? details?.Status : '--'}</p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('role')}:</h6>
                        <p>
                          {details?.Role === 'Client'
                            ? 'Employee'
                            : details?.Role}
                        </p>
                      </div>

                      <div className="user-details-block">
                        <h6>{t('additionalRole')}:</h6>
                        <p>
                          {details?.secondRole && Roles && Array.isArray(Roles)
                            ? Roles.find((el) => el.id === details?.secondRole)
                                ?.display ?? '--'
                            : '--'}
                        </p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('connectteam')}:</h6>
                        <p>{details?.connectTeam ? t('yes') : t('no')}</p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('kitchenname')}:</h6>
                        <p>{details?.Kitchens ? details?.Kitchens : '--'}</p>
                      </div>
                      {details?.RoleId === 4 ||
                      details?.RoleId === 6 ||
                      details?.RoleId === 8 ? (
                        <div className="user-details-block">
                          <h6>{t('factories')}:</h6>
                          <p>
                            {factories && details?.factoryId
                              ? factories.find(
                                  (el) => el.id === details.factoryId
                                )?.factoryName ?? '--'
                              : '--'}
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="user-details-block">
                        <h6>{t('phone')}:</h6>
                        <p>{details?.phone ? details?.phone : '--'}</p>
                      </div>

                      <div className="user-details-block">
                        <h6>{t('contractorName')}:</h6>
                        <p>
                          {details?.contractors &&
                          details?.contractors.length !== 0
                            ? details?.contractors
                                .map((elem) => elem.name)
                                .join(', ')
                            : '--'}
                        </p>
                      </div>

                      <div className="user-details-block">
                        <h6>{t('paymentMethod')}:</h6>
                        <p
                          className={`payment-method ${
                            details?.walletId != undefined
                              ? 'add-link'
                              : 'without-link'
                          }`}
                        >
                          {(details?.RoleId !== 6 && details?.RoleId !== 8) ||
                          (details?.RoleId === 8 &&
                            details?.allContractorInfo &&
                            details?.allContractorInfo?.isInvoice === 0) ||
                          (details?.RoleId === 6 &&
                            details?.allContractorInfo &&
                            details?.allContractorInfo?.isInvoice === 0)
                            ? details?.paymentMethod
                                ?.filter((el) => el !== 'invoice')
                                ?.map((el, i, arr) => {
                                  return el === 'Wallet' &&
                                    details?.walletId != undefined ? (
                                    <p
                                      role="presentation"
                                      onClick={() =>
                                        handleWallet(details?.walletId)
                                      }
                                    >
                                      {t('wallet')}
                                      <>
                                        ({details?.amount ? details?.amount : 0}
                                        )
                                      </>
                                      <>
                                        {' '}
                                        {arr.length === 1 ||
                                        arr.length - 1 === i
                                          ? ' '
                                          : ', '}
                                      </>
                                    </p>
                                  ) : el === 'Salary' ? (
                                    t('paymentOption-salary') +
                                    (arr.length === 1 || arr.length - 1 === i
                                      ? ' '
                                      : ', ')
                                  ) : el === 'Cibus' ? (
                                    t('paymentOption-cibus') +
                                    (arr.length === 1 || arr.length - 1 === i
                                      ? ' '
                                      : ', ')
                                  ) : el === 'Credit Card' ? (
                                    t('paymentOption-creditCard') +
                                    (arr.length === 1 || arr.length - 1 === i
                                      ? ' '
                                      : ', ')
                                  ) : el === 'Invoice' ? (
                                    t('invoice') +
                                    (arr.length === 1 || arr.length - 1 === i
                                      ? ' '
                                      : ', ')
                                  ) : el === 'Wallet' ? (
                                    t('wallet') +
                                    '(0)' +
                                    (arr.length === 1 || arr.length - 1 === i
                                      ? ' '
                                      : ', ')
                                  ) : (
                                    '--'
                                  );
                                })
                            : details.allContractors &&
                                details.allContractors.length !== 0 &&
                                Boolean(
                                  details.allContractors.find(
                                    (elem) => elem?.isInvoice === 0
                                  )
                                )
                              ? paymentMethodsForCA
                              : t('invoice')}
                        </p>
                      </div>
                      {details?.RoleId === 8 ? (
                        <>
                          <div className="user-details-block">
                            <h6>{t('startDate')}:</h6>
                            <p>
                              {details?.hireFrom ? details?.hireFrom : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('endDate')}:</h6>
                            <p>
                              {details?.hireTill ? details?.hireTill : '--'}
                            </p>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      {details?.RoleId !== 8 ? (
                        <>
                          <div className="user-details-block">
                            <h6> {t('paymentOption-creditCard')}:</h6>
                            <p>
                              <input
                                type="checkbox"
                                checked={details?.creditCard}
                              />
                            </p>
                          </div>
                          {cibusEnabled && (
                            <div className="user-details-block">
                              <h6>
                                {fieldNamesSettings?.find(
                                  (el) => el.value === 'magneticStripe'
                                )
                                  ? fieldNamesSettings
                                      ?.find(
                                        (el) => el.value === 'magneticStripe'
                                      )
                                      ?.translations.find(
                                        (el) =>
                                          el.language.shortCode == i18n.language
                                      )?.text
                                  : t('magneticStripe')}
                                :
                              </h6>
                              <p>
                                {details?.['magneticStripe']
                                  ? details?.['magneticStripe']
                                  : '--'}
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        ''
                      )}

                      {details?.RoleId === 8 ? (
                        <>
                          <div className="user-details-block">
                            <h6>{t('guestLink')}:</h6>
                            <p>
                              {details?.generatedLink
                                ? details?.generatedLink
                                : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('guestId')}:</h6>
                            <p>{details?.guestId ? details?.guestId : '--'}</p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('company')}:</h6>
                            <p>{details?.company ? details?.company : '--'}</p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('visitorCountry')}:</h6>
                            <p>
                              {details?.visitorCountry
                                ? countryOptions.find(
                                    (el) =>
                                      el.value == details?.visitorCountry ||
                                      el.label == details?.visitorCountry
                                  )?.label
                                : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('visitPurpose')}:</h6>
                            <p>
                              {details?.visitPurpose
                                ? visitPurposeValues.find(
                                    (el) =>
                                      el.value == details?.visitPurpose ||
                                      el.label == details?.visitPurpose
                                  )?.label
                                : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('isMultiOrders')}:</h6>
                            <p>
                              <input
                                type="checkbox"
                                checked={details?.isMultiOrders}
                              />
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('invitedBy')}:</h6>
                            <p>
                              {details?.invitedBy
                                ? allUsers.find(
                                    (el) => el.value == details?.invitedBy
                                  )?.label
                                : '--'}
                            </p>
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                      <div className="user-details-block">
                        <h6>{t('reservations')}:</h6>
                        <div
                          className={`payment-method ${
                            details?.userId != undefined
                              ? 'add-link'
                              : 'without-link'
                          }`}
                        >
                          <p
                            role="presentation"
                            onClick={() =>
                              handleOrderLink(
                                details?.userId,
                                details?.['Registration Date']
                              )
                            }
                          >
                            {details?.totalReservation}
                          </p>
                        </div>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('avgTimeSpan')}:</h6>
                        <p>{timeSpan ?? '--'}</p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('registrationDate')}:</h6>
                        <p>
                          {details?.['Registration Date']
                            ? details?.['Registration Date']
                            : '--'}
                        </p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('updatedOn')}:</h6>
                        <p>{details?.updatedAt ? details?.updatedAt : '--'}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {user.roleId === 10 ? (
                  <div className="modal-footer-btn">
                    <Button onClick={() => CopyDataUrl(details)}>
                      <i class="las la-utensils"></i>
                      {t('copyUrl')}
                    </Button>

                    {attendanceEnabled && details?.RoleId !== 8 ? (
                      <Button
                        onClick={() =>
                          history.push(
                            `/attendance?user=${
                              details?.userId
                            }&fromDate=${moment(
                              new Date(details['Registration Date'])
                            ).format('YYYY-MM-DD')}&toDate=${moment(
                              new Date()
                            ).format('YYYY-MM-DD')}`
                          )
                        }
                      >
                        <i class="las la-address-card"></i>
                        {t('attendance')}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                ) : details?.RoleId === 8 ? (
                  <div className="modal-footer-btn">
                    <Button
                      onClick={() => {
                        let ids = details.kitchensIds;
                        let newMealTypeDateArray = [];
                        if (details.kitchensIds?.length) {
                          details.kitchensIds?.forEach((el) => {
                            let newMealTypeInfo = getMealTypes?.filter(
                              (elInfo) => elInfo?.kitchenId === el
                            );
                            newMealTypeDateArray = [
                              ...newMealTypeDateArray,
                              ...newMealTypeInfo,
                            ];
                          });
                        }
                        setMealTypesKitchenWise(newMealTypeDateArray);
                        setKitchensIds(ids);
                        if (details['RoleId'] === 6) {
                          setShowContractor(true);
                        }
                        setPayload({
                          userId: details['userId'],
                          card: details['Card Number'],
                          email: details['Email'],
                          fName: details['firstName'],
                          lName: details['lastName'],
                          phone: details['phone']
                            ? details['phone'].split('-').join('')
                            : details['phone'],
                          kitchensIds: ids,
                          employeeId: details['employeeId'],
                          role: [
                            Roles.find((el) => el.display === details['Role'])
                              ?.id,
                          ],
                          secondRole: details['secondRole'],
                          contractors: details['contractors'],
                          status: details?.Status,
                          magneticStripe: details?.magneticStripe,
                          hireFrom: details['hireFrom'],
                          hireTill: details['hireTill'],
                          mealTypeId: details['mealTypeId'] || [],
                          isPasswordTemporary: false,
                          mealTypesIds: details['mealTypeId'] || [],
                          company: details['company'],
                          visitorCountry: details['visitorCountry'],
                          visitPurpose: details['visitPurpose'],
                          guestId: details['guestId'],
                          isMultiOrders: details['isMultiOrders'],
                          invitedBy: details['invitedBy'],
                          connectTeam: details['connectTeam'],
                        });
                        let mealTypeIdList = details['mealTypeId'] || [];
                        let array = [];
                        if (mealTypeIdList?.length) {
                          let aa = mealTypeIdList?.map((el) => array.push(el));
                        }
                        setMealTypesIds(array);
                        handleOpen();
                      }}
                    >
                      <i class="las la-pen"></i>
                      {t('edit')}
                    </Button>

                    <Button
                      onClick={() => {
                        let ids = details.kitchensIds;
                        let newMealTypeDateArray = [];
                        if (details.kitchensIds?.length) {
                          details.kitchensIds?.forEach((el) => {
                            let newMealTypeInfo = getMealTypes?.filter(
                              (elInfo) => elInfo?.kitchenId === el
                            );
                            newMealTypeDateArray = [
                              ...newMealTypeDateArray,
                              ...newMealTypeInfo,
                            ];
                          });
                        }
                        setMealTypesKitchenWise(newMealTypeDateArray);
                        setKitchensIds(ids);
                        if (details['RoleId'] === 6) {
                          setShowContractor(true);
                        }
                        setPayload({
                          kitchensIds: ids,
                          role: [
                            Roles.find((el) => el.display === details['Role'])
                              ?.id,
                          ],
                          contractors: details['contractors'],
                          status: details?.Status,
                          hireFrom: details['hireFrom'],
                          hireTill: details['hireTill'],
                          mealTypeId: details['mealTypeId'] || [],
                          isPasswordTemporary: details?.isPasswordTemporary,
                          mealTypesIds: details['mealTypeId'] || [],
                          company: details['company'],
                          visitorCountry: details['visitorCountry'],
                          visitPurpose: details['visitPurpose'],
                          guestId: details['guestId'],
                          isMultiOrders: details['isMultiOrders'],
                          invitedBy: details['invitedBy'],
                          secondRole: details['secondRole'],
                          connectTeam: details['connectTeam'],
                        });
                        let mealTypeIdList = details['mealTypeId'] || [];
                        let array = [];
                        if (mealTypeIdList?.length) {
                          let aa = mealTypeIdList?.map((el) => array.push(el));
                        }
                        setMealTypesIds(array);
                        handleOpen('recreate');
                      }}
                    >
                      <i class="las la-user-circle"></i>
                      {t('createAnotherUser')}
                    </Button>
                    {details?.StatusVal === 'deleted' ? (
                      <Button
                        onClick={() => handleConfirmation(details['userId'], 3)}
                      >
                        <i class="las la-undo-alt"></i>
                        {t('activate')}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleConfirmation(details['userId'])}
                      >
                        <i class="las la-trash"></i>
                        {t('delete')}
                      </Button>
                    )}

                    {UserStatuses?.find((el) => el.display === details?.Status)
                      ?.value === 'created' ||
                    UserStatuses?.find((el) => el.display === details?.Status)
                      ?.value === 'pending' ? (
                      <Button onClick={() => CopyDataUrl(details)}>
                        <i class="las la-copy"></i>
                        {t('copyUrl')}
                      </Button>
                    ) : (
                      <Button onClick={() => CopyDataUrl(details)}>
                        <i class="las la-copy"></i>
                        {t('copyUrl')}
                      </Button>
                    )}
                  </div>
                ) : (
                  <div className="modal-footer-btn">
                    <Button
                      onClick={() => {
                        let ids = details.kitchensIds;

                        setKitchensIds(ids);
                        if (details['RoleId'] === 6) {
                          setShowContractor(true);
                        }
                        setPayload({
                          kitchensIds: ids,
                          role: [
                            Roles.find((el) => el.display === details['Role'])
                              ?.id,
                          ],
                          contractors: details['contractors'],
                          status: details?.Status,
                          magneticStripe:
                            details['RoleId'] === 6
                              ? details?.magneticStripe
                              : '',
                          mealTypeId: details?.mealTypeId,
                          isPasswordTemporary: details?.isPasswordTemporary,
                        });
                        handleOpen('recreate');
                      }}
                    >
                      <i class="las la-user-circle"></i>
                      {t('createAnotherUser')}
                    </Button>
                    <Button
                      onClick={() => {
                        let ids = details.kitchensIds;

                        setKitchensIds(ids);
                        if (details['RoleId'] === 6) {
                          setShowContractor(true);
                        }
                        setPayload({
                          userId: details['userId'],
                          card: details['Card Number'],
                          email: details['Email'],
                          fName: details['firstName'],
                          lName: details['lastName'],
                          phone: details['phone']
                            ? details['phone'].split('-').join('')
                            : details['phone'],
                          kitchensIds: ids,
                          employeeId: details['employeeId'],
                          role: [
                            Roles.find((el) => el.display === details['Role'])
                              ?.id,
                          ],
                          contractors: details['contractors'],
                          status: details?.Status,
                          magneticStripe: details?.magneticStripe,
                          mealTypeId: details?.mealTypeId,
                          isPasswordTemporary: details?.isPasswordTemporary,
                          secondRole: details['secondRole'],
                          connectTeam: details['connectTeam'],
                        });
                        handleOpen();
                      }}
                    >
                      <i class="las la-pen"></i>
                      {t('edit')}
                    </Button>
                    {details?.StatusVal === 'deleted' ? (
                      <Button
                        onClick={() => handleConfirmation(details['userId'], 3)}
                      >
                        <i class="las la-undo-alt"></i>
                        {t('activate')}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleConfirmation(details['userId'])}
                      >
                        <i class="las la-trash"></i>
                        {t('delete')}
                      </Button>
                    )}

                    {UserStatuses?.find((el) => el.display === details?.Status)
                      ?.value === 'created' ||
                    UserStatuses?.find((el) => el.display === details?.Status)
                      ?.value === 'pending' ? (
                      <Button onClick={() => ResendVerification(details)}>
                        <i class="las la-share-square"></i>
                        {t('resend')}
                      </Button>
                    ) : (
                      <Button onClick={() => ResendVerification(details)}>
                        <i class="las la-share-square"></i>
                        {t('resend')}
                      </Button>
                    )}

                    {UserStatuses?.find((el) => el.display === details?.Status)
                      ?.value === 'created' ||
                    UserStatuses?.find((el) => el.display === details?.Status)
                      ?.value === 'pending' ? (
                      <Button onClick={() => CopyDataUrl(details)}>
                        <i class="las la-copy"></i>
                        {t('copyUrl')}
                      </Button>
                    ) : (
                      <Button onClick={() => CopyDataUrl(details)}>
                        <i class="las la-copy"></i>
                        {t('copyUrl')}
                      </Button>
                    )}
                    {attendanceEnabled ? (
                      <Button
                        onClick={() =>
                          history.push(
                            `/attendance?user=${
                              details?.userId
                            }&fromDate=${moment(
                              new Date(details['Registration Date'])
                            ).format('YYYY-MM-DD')}&toDate=${moment(
                              new Date()
                            ).format('YYYY-MM-DD')}`
                          )
                        }
                      >
                        <i class="las la-address-card"></i>
                        {t('attendance')}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={1} class="overflow-hidden">
              {historyData && historyData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className="audit-table-container"
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{`${t('date')} ${t('time')}`}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('user')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('action')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2">
                          <div className="d-flex m-0 w-100">
                            <strong className="history-column-fieldname">{`${t(
                              'fieldName'
                            )}`}</strong>
                            <strong className="history-column-values">{`${t(
                              'oldValue'
                            )}`}</strong>
                            <strong className="history-column-values">{`${t(
                              'newValue'
                            )}`}</strong>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyData.map((row) => (
                        <StyledTableRow
                          key={row.name}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell className="p-2">
                            <p className="audit-details-text">
                              {moment(row?.createdAt).format('YYYY-MM-DD')}
                            </p>
                            <p className="audit-details-text">
                              {moment(row?.createdAt).format('HH:mm:ss')}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">{row?.madeBy}</p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">
                              {t(row.typeAction)}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <div className="d-flex m-0 flex-column gap-2">
                              {Object.keys(row.diff).length > 0
                                ? Object.entries(row.diff).map((el) => (
                                    <div>
                                      <StyledTableCell className="p-1 history-column-fieldname border-bottom-0">
                                        <p className="audit-details-text">{`${
                                          t(el[0]) || '--'
                                        }:`}</p>
                                      </StyledTableCell>
                                      <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                        <p className="pl-1 pr-1 audit-details-text">{`${
                                          el[1] &&
                                          el[1]?.old &&
                                          el[0] === 'contractorsIds'
                                            ? el[1]?.old.length === 0
                                              ? '--'
                                              : contractorList
                                                  .filter((obj1) =>
                                                    el[1]?.old.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                            : el[1] &&
                                                el[1]?.old &&
                                                el[0] === 'kitchensIds'
                                              ? el[1]?.old.length === 0
                                                ? '--'
                                                : Kitchens.filter((obj1) =>
                                                    el[1]?.old.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                    .map((el) => el.name)
                                                    .join(', ')
                                              : el[1] &&
                                                  el[1]?.old &&
                                                  el[0] === 'roleId'
                                                ? Roles.filter(
                                                    (obj1) =>
                                                      el[1]?.old === obj1.id
                                                  ).map((el) => el.display)[0]
                                                : el[1] &&
                                                    el[1]?.old &&
                                                    el[0] === 'userStatusId'
                                                  ? UserStatuses.filter(
                                                      (obj1) =>
                                                        el[1]?.old === obj1.id
                                                    ).map((el) => el.display)[0]
                                                  : el[1] &&
                                                      el[1]?.old &&
                                                      el[0] === 'mealTypeId'
                                                    ? getMealTypes
                                                        .filter((obj1) =>
                                                          el[1]?.old.includes(
                                                            obj1.id
                                                          )
                                                        )
                                                        .map((el) => el.display)
                                                        .join(', ')
                                                    : el[1] &&
                                                        el[1]?.old &&
                                                        el[0] === 'invitedBy'
                                                      ? allUsers.find((elem) =>
                                                          elem.value ==
                                                          Array.isArray(
                                                            el[1]?.old
                                                          )
                                                            ? el[1]?.old[0]
                                                            : el[1]?.old
                                                        )?.label
                                                      : el[1]?.old ||
                                                          typeof el[1]?.old ===
                                                            'boolean'
                                                        ? el[1]?.old
                                                        : '--'
                                        }`}</p>
                                      </StyledTableCell>
                                      <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                        <p className="pl-1 pr-1 audit-details-text">{`${
                                          el[1] &&
                                          el[1]?.new &&
                                          el[0] === 'contractorsIds'
                                            ? el[1]?.new.length === 0
                                              ? '--'
                                              : contractorList
                                                  .filter((obj1) =>
                                                    el[1]?.new.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                            : el[1] &&
                                                el[1]?.new &&
                                                el[0] === 'kitchensIds'
                                              ? Kitchens.filter((obj1) =>
                                                  el[1]?.new.find(
                                                    (obj2) => obj2 === obj1.id
                                                  )
                                                )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                              : el[1] &&
                                                  el[1]?.new &&
                                                  el[0] === 'roleId'
                                                ? Roles.filter(
                                                    (obj1) =>
                                                      (el[1]?.new[0] ??
                                                        el[1]?.new) === obj1.id
                                                  ).map((el) => el.display)[0]
                                                : el[1] &&
                                                    el[1]?.new &&
                                                    el[0] === 'userStatusId'
                                                  ? UserStatuses.filter(
                                                      (obj1) =>
                                                        el[1]?.new === obj1.id
                                                    ).map((el) => el.display)[0]
                                                  : el[1] &&
                                                      el[1]?.new &&
                                                      el[0] === 'mealTypeId'
                                                    ? getMealTypes
                                                        .filter((obj1) =>
                                                          el[1]?.new.includes(
                                                            obj1.id
                                                          )
                                                        )
                                                        .map((el) => el.display)
                                                        .join(', ')
                                                    : el[1] &&
                                                        el[1]?.new &&
                                                        el[0] === 'invitedBy'
                                                      ? allUsers.find((elem) =>
                                                          elem.value ===
                                                          Array.isArray(
                                                            el[1]?.new
                                                          )
                                                            ? el[1]?.new[0]
                                                            : el[1]?.new
                                                        )?.label
                                                      : el[1]?.new ||
                                                          typeof el[1]?.new ===
                                                            'boolean'
                                                        ? el[1]?.new
                                                        : '--'
                                        }`}</p>
                                      </StyledTableCell>
                                    </div>
                                  ))
                                : '--'}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                  <Typography className="history-text-styles fs-5">
                    {t('emptyHistoryMess')}
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Typography>
          {/* <div className="view-popup-btn">
            <button className="close-modal-btn" onClick={handleClose}>
              {t('close')}
            </button>
          </div> */}
        </Box>
      </Modal>
    </>
  );
};
export default ViewDetails;
