import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import http from '../../services/http';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
  minHeight: '35vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ContractDetails = ({
  open,
  handleClose,
  details,
  handleConfirmation,
  handleOpen,
  setPayload,
  payload,
  initialState,
  cibusEnabled,
  fieldNamesSettings,
  user,
}) => {
  let { t, i18n } = useTranslation();
  const location = useLocation();
  const [historyData, setHistoryData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await http.get('audit/getByParams', {
        filter: {
          entity: 'contractor',
          actionEntityId: details.userId,
        },
      });
      const validData = data.data.map((el) => {
        if (el.typeAction === 'update') {
        } else {
          return el;
        }
      });
      setHistoryData(data.data);
    };
    if (open) {
      fetchData();
    }
  }, [open]);
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-employee-popup"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('groupsManagement')}
          </Typography>
          <div className="close-btn-view">
            <img
              src={cancel}
              alt="close"
              onClick={() => {
                handleClose();
                setTabIndex(0);
              }}
            />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab label={t('mainInfo')} {...a11yProps(0)} />

              <Tab label={t('history')} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabIndex} index={0}>
            <div className="view-more-popup">
              {/* <div className="back">
        <img src={back} alt="back" onClick={handleBack} />
      </div> */}
              <div className="user-detail-container">
                <div className="user-details-row">
                  <div className="user-details-block">
                    <h6>{t('contractorName')}:</h6>
                    <p>{details?.name ? details?.name : '--'}</p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('email')}:</h6>
                    <p>
                      {details?.contactEmail ? details?.contactEmail : '--'}
                    </p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('companyNumber')}:</h6>
                    <p>
                      {details?.companyNumber ? details?.companyNumber : '--'}
                    </p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('phone')}:</h6>
                    <p>
                      {details?.contactPhone ? details?.contactPhone : '--'}
                    </p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('contactPersonName')}:</h6>
                    <p>
                      {details?.contactPersonName
                        ? details?.contactPersonName
                        : '--'}
                    </p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('invoice')}:</h6>
                    <p>{Boolean(details?.isInvoice) ? t('yes') : t('no')}</p>
                  </div>
                  {cibusEnabled && (
                    <div className="user-details-block">
                      <h6>
                        {fieldNamesSettings?.find(
                          (el) => el.value === 'magneticStripe'
                        )
                          ? fieldNamesSettings
                              ?.find((el) => el.value === 'magneticStripe')
                              ?.translations.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('magneticStripe')}
                        :
                      </h6>
                      <p>
                        {!Boolean(details?.isInvoice) &&
                        Boolean(details?.magneticStripe)
                          ? details?.magneticStripe
                          : '--'}
                      </p>
                    </div>
                  )}
                  <div className="user-details-block">
                    <h6>{t('cutOrderTime')}:</h6>
                    <p>{details?.cutOffTime ? details?.cutOffTime : '--'}</p>
                  </div>
                  {user.roleId !== 10 && (
                    <div className="modal-footer-btn">
                      <Button
                        onClick={() => {
                          setPayload({
                            name: details?.name,
                            userId: details?.userId,
                            companyNumber: details?.companyNumber,
                            contactEmail: details?.contactEmail,
                            contactPersonName: details?.contactPersonName,
                            contactPhone: details?.contactPhone
                              ? details?.contactPhone?.split('-')?.join('')
                              : details?.contactPhone,
                            magneticStripe: details?.magneticStripe,
                            isInvoice: details?.isInvoice,
                            cutOffTime: details?.cutOffTime,
                          });
                          handleOpen();
                        }}
                      >
                        <i class="las la-pen" title="edit"></i>
                        {t('edit')}
                      </Button>
                      <Button
                        onClick={() => handleConfirmation(details?.userId)}
                      >
                        <i class="las la-trash" title="delete"></i>
                        {t('delete')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            {historyData && historyData.length > 0 ? (
              <TableContainer
                component={Paper}
                className="audit-table-container"
              >
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="p-2" width={125}>
                        <strong>{`${t('date')} ${t('time')}`}</strong>
                      </StyledTableCell>
                      <StyledTableCell width={125} className="p-2">
                        <strong>{t('user')}</strong>
                      </StyledTableCell>
                      <StyledTableCell width={125} className="p-2">
                        <strong>{t('action')}</strong>
                      </StyledTableCell>
                      <StyledTableCell className="p-2">
                        <div className="d-flex m-0 w-100">
                          <strong className="history-column-fieldname">{`${t(
                            'fieldName'
                          )}`}</strong>
                          <strong className="history-column-values">{`${t(
                            'oldValue'
                          )}`}</strong>
                          <strong className="history-column-values">{`${t(
                            'newValue'
                          )}`}</strong>
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ maxWidth: '90vh' }}>
                    {historyData.map((row) => (
                      <StyledTableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <StyledTableCell className="p-2">
                          <p className="audit-details-text">
                            {moment(row?.createdAt).format('YYYY-MM-DD')}
                          </p>
                          <p className="audit-details-text">
                            {moment(row?.createdAt).format('HH:mm:ss')}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell align="center" className="p-2">
                          <p className="audit-details-text">{row?.madeBy}</p>
                        </StyledTableCell>
                        <StyledTableCell align="center" className="p-2">
                          <p className="audit-details-text">
                            {t(row.typeAction)}
                          </p>
                        </StyledTableCell>
                        <StyledTableCell align="center" className="p-2">
                          <div className="d-flex m-0 flex-column gap-2">
                            {Object.keys(row.diff).length > 0
                              ? Object.entries(row.diff).map((el) => (
                                  <div>
                                    <StyledTableCell className="p-1 history-column-fieldname border-bottom-0">
                                      <p className="audit-details-text">{`${
                                        t(el[0]) || '--'
                                      }:`}</p>
                                    </StyledTableCell>
                                    <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                      {' '}
                                      <p className="pl-1 pr-1 audit-details-text">{`${
                                        el[1]?.old ||
                                        typeof el[1]?.old === 'boolean' ||
                                        el[1]?.old === 0
                                          ? el[1]?.old
                                          : '--'
                                      }`}</p>
                                    </StyledTableCell>
                                    <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                      <p className="pl-1 pr-1 audit-details-text">{`${
                                        el[1]?.new ||
                                        typeof el[1]?.new === 'boolean' ||
                                        el[1]?.new === 0
                                          ? el[1]?.new
                                          : '--'
                                      }`}</p>
                                    </StyledTableCell>
                                  </div>
                                ))
                              : '--'}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                <Typography className="history-text-styles fs-5">
                  {t('emptyHistoryMess')}
                </Typography>
              </div>
            )}
          </CustomTabPanel>
        </Typography>
        {/* <div className="view-popup-btn">
          <button className="close-modal-btn" onClick={handleClose}>
            {t('close')}
          </button>
        </div> */}
      </Box>
    </Modal>
  );
};
export default ContractDetails;
