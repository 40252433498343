import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { validateContractor, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '20px 30px',
  p: 4,
  borderRadius: '5px',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        type={type}
      />
    </>
  );
};

const AddContractorPopup = ({
  open,
  handleClose,
  handleAddNewUser,
  Roles,
  Kitchens,
  UserStatuses,
  setPayload,
  payload,
  handleUpdateUser,
  setIsEdit,
  isUpdate,
  userData,
  t,
  i18n,
  setRolesIds,
  rolesIds,
  setKitchensIds,
  kitchensIds,
  errors,
  setErrors,
  newRoles,
  user,
  cibusEnabled,
  fieldNamesSettings,
}) => {
  const KitchensMap = Kitchens.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const RolesMap_ValueId = newRoles?.reduce((acc, role) => {
    return { ...acc, [role.id]: role.display };
  }, {});
  const StatusMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.display };
  }, {});
  useEffect(() => {
    if (!open) {
      setIsInvoice(1);
    }
  }, [open]);
  const [role] = useState(4);
  const [pError, setPError] = useState(false);
  const [status, setStatus] = useState({ value: 3, label: 'Active' });
  const [isInvoice, setIsInvoice] = useState(1);
  const handleChange = (name, value) => {
    const { isValid, error } = validateUsers(value, name);
    setErrors({ ...errors, ...error });
    setPayload({ ...payload, [name]: value });
  };
  const handleIsInvoice = (data) => {
    let value = 0;
    if (data === 0) {
      value = 1;
      setPayload({ ...payload, magneticStripe: '', isInvoice: value });
    } else {
      setPayload({ ...payload, isInvoice: value });
    }
    setIsInvoice(value);
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      name: payload.name,
      contactPersonName: payload.contactPersonName,
      contactEmail: payload.contactEmail,
      contactPhone: payload.contactPhone,
      companyNumber: payload.companyNumber,
      isInvoice: isUpdate ? payload?.isInvoice : isInvoice,
      magneticStripe: payload?.magneticStripe ?? '',
      cutOffTime: payload?.cutOffTime,
    };
    const { isValid, errors } = validateContractor(details);
    setErrors(errors);

    if (isValid) {
      await handleAddNewUser({ ...details });
    }
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div className="user-popup-heading">
          <h6>{isUpdate ? t('updateContractor') : t('addContractor')}</h6>
          <div className="close-button">
            <img
              src={cancel}
              alt="close"
              onClick={() => {
                setIsInvoice(undefined);
                handleClose();
              }}
            />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <form>
            <div className="item">
              <div className="input-field">
                <GetInputField
                  placeholder={t('name')}
                  label={t('name')}
                  name="name"
                  type="text"
                  value={payload['name']}
                  cb={(value) => {
                    handleChange('name', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">{t(errors.name || errors.name)}</p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('contactPersonName')}
                  label={t('contactPersonName')}
                  name="contactPersonName"
                  type="text"
                  value={payload['contactPersonName']}
                  cb={(value) => {
                    handleChange('contactPersonName', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {t(errors.contactPersonName || errors.contactPersonName)}
                  </p>
                )}
              </div>

              <div className="input-field">
                <GetInputField
                  placeholder={t('contactPhone')}
                  label={t('contactPhone')}
                  name="contactPhone"
                  type="number"
                  value={payload['contactPhone']}
                  cb={(value) => {
                    handleChange('contactPhone', value);
                  }}
                  required
                />
                {errors && (
                  <p className="error-msg">{t(errors.contactPhone)}</p>
                )}
              </div>

              <div className="input-field">
                <GetInputField
                  placeholder={t('contactEmail')}
                  label={t('contactEmail')}
                  name="contactEmail"
                  type="email"
                  value={payload['contactEmail']}
                  cb={(value) => {
                    handleChange('contactEmail', value);
                  }}
                  required
                />
                {errors && (
                  <p className="error-msg">{t(errors.contactEmail)}</p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('companyNumber')}
                  label={t('companyNumber')}
                  name="companyNumber"
                  type="number"
                  value={payload['companyNumber']}
                  cb={(value) => {
                    handleChange('companyNumber', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {t(errors.companyNumber || errors.companyNumber)}
                  </p>
                )}
              </div>
              <div
                className="list-details toggle-mealtype input-field"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <label>{t('invoice')}:</label>
                <div>
                  <button
                    type="button"
                    className={`toggle-btn ${
                      (isUpdate ? payload?.isInvoice : isInvoice)
                        ? 'toggle-btn-on'
                        : 'toggle-btn-off'
                    }`}
                    onClick={() => handleIsInvoice(isInvoice)}
                  >
                    <span></span>
                  </button>
                </div>
              </div>
              {!(isUpdate ? payload?.isInvoice : isInvoice) && cibusEnabled && (
                <div className="input-field">
                  <GetInputField
                    placeholder={
                      fieldNamesSettings?.find(
                        (el) => el.value === 'magneticStripe'
                      )
                        ? fieldNamesSettings
                            ?.find((el) => el.value === 'magneticStripe')
                            ?.translations.find(
                              (el) => el.language.shortCode == i18n.language
                            )?.text
                        : t('magneticStripe')
                    }
                    label={
                      fieldNamesSettings?.find(
                        (el) => el.value === 'magneticStripe'
                      )
                        ? fieldNamesSettings
                            ?.find((el) => el.value === 'magneticStripe')
                            ?.translations.find(
                              (el) => el.language.shortCode == i18n.language
                            )?.text
                        : t('magneticStripe')
                    }
                    name="magneticStripe"
                    type="number"
                    value={payload['magneticStripe']}
                    cb={(value) => {
                      handleChange('magneticStripe', value);
                    }}
                  />
                  {errors && (
                    <p className="error-msg">
                      {t(errors.magneticStripe || errors.magneticStripe)}
                    </p>
                  )}
                </div>
              )}
              <div className="input-field">
                <GetInputField
                  placeholder={t('cutOrderTime')}
                  label={t('cutOrderTime')}
                  name="cutOffTime"
                  type="number"
                  value={payload['cutOffTime']}
                  cb={(value) => {
                    handleChange('cutOffTime', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {t(errors.cutOffTime || errors.cutOffTime)}
                  </p>
                )}
              </div>
              <div
                className="list-details toggle-mealtype input-field"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <label>{t('isPaymentRequiredForPickup')}:</label>
                <div>
                  <button
                    type="button"
                    className={`toggle-btn ${
                      payload?.isPaymentRequiredForPickup
                        ? 'toggle-btn-on'
                        : 'toggle-btn-off'
                    }`}
                    onClick={() =>
                      handleChange(
                        'isPaymentRequiredForPickup',
                        !payload?.isPaymentRequiredForPickup
                      )
                    }
                  >
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
          {isUpdate ? (
            <div className="add-employee-btn">
              <div onClick={handleUpdateUser}>
                <ActiveButton label={t('update')} arrow={false} />
              </div>
            </div>
          ) : (
            <div className="add-employee-btn">
              <div onClick={handleFormSubmit}>
                <ActiveButton label={t('add')} arrow={false} />
              </div>
            </div>
          )}
        </Typography>
      </Box>
    </Modal>
  );
};
export default AddContractorPopup;
